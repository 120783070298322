/**
 * Application Name: Zevcore-WAS
 * Author: Zevcore Private Limited
 * Starting date: 15.12.2022
 * Developer Name: Anand Krishna
 * Last modified date: 10.09.2022
 * Last modified by: Anand Krishna
 */

import {
  ActionIcon,
  AppShell,
  Header,
  useMantineTheme,
  Menu,
  Text,
  Button,
  Group,
  Code,
  TextInput,
  Indicator,
  Tooltip,
  LoadingOverlay,
} from "@mantine/core"; //For import mantine required functions and theme //for import mantine required functions and theme
import React, { useState, useEffect } from "react";
import {
  MoonStars,
  Sun,
  Settings,
  Logout,
  X,
  WindowMinimize,
  WindowMaximize,
  Minus,
  Bell,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon
import { IconSearch } from "@tabler/icons";
import { useLocalStorage, useClickOutside } from "@mantine/hooks";
import { useModals, openModal, closeAllModals } from "@mantine/modals";
import { useNavigate, Outlet } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import {
  SpotlightProvider,
  useSpotlight,
  openSpotlight,
} from "@mantine/spotlight"; // For enable search from navigation

// For imports the logo images for navigation
import Icon from "../assets/images/task.png";
import useStyles from "./Style"; // Import custom styles
import Home from "../pages/Home";
import Navigation from "./Navigation";
import Lottie from "lottie-react"; // For animations icons
import Logo from "./Logo";

// Sidebar start
function Sidebar() {
  const [minimize, setMinimize] = useState(false);
  const theme = useMantineTheme();
  const [statusText, setStatusText] = useState("");

  const [update, setUpdate] = useState(0);

  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const dark = colorScheme === "dark";
  // const { classes } = useStyles();

  const [note, setNote] = useState(true);
  const [pCount, setPCount] = useLocalStorage({
    key: "depletion",
    defaultValue: 0,
  });
  const [notificationDrawer, setNotificationDrawer] = useState(false);
  const modals = useModals();
  let navigate = useNavigate();

  // For search tooltip of navigation list
  const actions = [
    {
      title: "Dashboard",
      onTrigger: () => navigate("/"),
    },
    {
      title: "Add Sale",
      onTrigger: () => navigate("/add_sale"),
    },
    { title: "Sales", onTrigger: () => navigate("/sale") },
    { title: "Add Estimate", onTrigger: () => navigate("/add_estimate") },
    { title: "Estimates", onTrigger: () => navigate("/estimate") },
    { title: "KPI", onTrigger: () => navigate("/kpi") },
    { title: "Vendors", onTrigger: () => navigate("/vendors") },
    { title: "Stock In", onTrigger: () => navigate("/stock_in") },
    { title: "Stock View", onTrigger: () => navigate("/stock") },
    { title: "Purchase Order", onTrigger: () => navigate("/po") },
    { title: "Cash Ledger ", onTrigger: () => navigate("/cash") },
    { title: "Search Cash Ledger", onTrigger: () => navigate("/search_cash") },
    { title: "Category", onTrigger: () => navigate("/category") },
    { title: "Products", onTrigger: () => navigate("/products") },
  ];

  const [lDAni, setLDAni] = useState(false);
  const [loadingTime, setLoadingTime] = useState(new Date());

  // For backup the database in rar file
  const dbDownload = async () => {
    setLDAni(true);
    setLoadingTime(new Date());
    const response = await handlePath();
    if (response.status == 200) {
      setLDAni(false);
      setLoadingTime(new Date());
      openModal({
        title: "Backup zip created",
        children: (
          <>
            <Text>Backup zip created in zip and stored in downloads</Text>
            <Button fullWidth mt="md" onClick={closeAllModals}>
              Close
            </Button>
          </>
        ),
      });
    }
  };

  const handleReload = async (e) => {};
  const handleMinimize = async (e) => {};
  const handleUnmaximize = async (e) => {};
  const handleMaximize = async (e) => {};
  const handleHide = async (e) => {};
  const handleClose = async (e) => {};
  const handlePath = async (e) => {};
  const UpdateThisApplication = async (e) => {};

  const [visible, setVisible] = useState(false);
  const customLoader = (
    <svg
      width="54"
      height="54"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke={"#043c64"}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );

  return (
    <div>
      {visible == true ? (
        <>
          <div className="overlay">
            <LoadingOverlay
              zIndex={998}
              loader={customLoader}
              visible={visible}
              overlayBlur={2}
            />
            <div className="textOverlay">Getting ready to update...</div>
          </div>
        </>
      ) : null}

      {/* Main start */}
      <AppShell
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
        // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
        navbarOffsetBreakpoint="sm"
        // fixed prop on AppShell will be automatically added to Header and Navbar
        fixed
        header={
          <Header
            height={62}
            py={2}
            style={{
              borderBottom: "0px",
            }}
            className="border-bottom noprint"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Navigation start */}
              <div
                style={{
                  backgroundColor:
                    theme.colorScheme === "dark" ? "#2d2d2d" : "#EEEEEE",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 13,
                }}
              >
                <Logo />
                <div
                  className="no-drag"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {/* Show all the list of navigations */}
                  <Navigation />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Dark light switch */}
                  <ActionIcon
                    className="zc-mt-1 no-drag"
                    color={dark ? "yellow" : "blue"}
                    onClick={() => toggleColorScheme()}
                    title="Toggle dark light"
                  >
                    {dark ? (
                      <Sun strokeWidth="0.8" size={20} />
                    ) : (
                      <MoonStars strokeWidth="0.8" size={20} />
                    )}
                  </ActionIcon>
                  {/* refresh page */}
                  {/* Bell icon for depletion indicated */}
                  <ActionIcon
                    className="zc-mt-1 no-drag"
                    color="dark"
                    onClick={() => setNotificationDrawer(!notificationDrawer)}
                    title="Notification"
                  >
                    <Indicator disabled={note} color="red">
                      <Bell strokeWidth="0.8" size={20} />
                    </Indicator>
                  </ActionIcon>

                  {/* For Profile Dropdown list */}
                  <Menu shadow="md" className="no-drag zc-mr-1" width={200}>
                    <Menu.Target>
                      <ActionIcon
                        className="zc-mt-1"
                        color="dark"
                        type="button"
                        title="Setting"
                      >
                        <Settings strokeWidth="0.8" size={20} />
                      </ActionIcon>
                    </Menu.Target>
                    {/* Profile setting page */}
                    <Menu.Dropdown>
                      {/* <Menu.Item
                        icon={<UserExclamation size={14} />}
                        onClick={() => navigate("/profile")}
                      >
                        Profile Settings
                      </Menu.Item> */}
                      <Menu.Item
                        icon={<Settings size={14} />}
                        onClick={() => navigate("/manages")}
                      >
                        Settings
                      </Menu.Item>
                      {/* For logout button */}
                      <Menu.Item
                        onClick={() => {
                          modals.openConfirmModal({
                            title: "Confirm Logout ",
                            children: (
                              <Text size="sm">
                                This action is so important that you are
                                required to confirm logout. Please click one of
                                these buttons to proceed.
                              </Text>
                            ),

                            labels: {
                              confirm: "Confirm",
                              cancel: "Cancel",
                            },
                            confirmProps: { color: "red" },
                            onCancel: () => console.log("Cancel"),
                            onConfirm: () => {
                              localStorage.clear();
                              navigate("/login");
                            },
                          });
                        }}
                        color="red"
                        icon={<Logout size={14} />}
                      >
                        Log Out
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                  {/* For backup db to zip */}
                  {/* For search bar with shot cut key */}
                  <SpotlightProvider
                    className="no-drag"
                    actions={actions}
                    searchPlaceholder="Search..."
                    // shortcut={["ctrl + k", "mod + K", "⌘ + K"]}
                    nothingFoundMessage="Nothing found..."
                  >
                    <div
                      style={{ width: 150 }}
                      className="no-drag"
                      onClick={openSpotlight}
                    >
                      <TextInput
                        variant="filled"
                        placeholder="Search"
                        onClick={openSpotlight}
                        size="xs"
                        pt={2}
                        pb={1}
                        // shortcut={["ctrl + k", "mod + K", "⌘ + K"]}
                        icon={<IconSearch size={12} stroke={1.5} />}
                        rightSectionWidth={70}
                        // rightSection={<Code>Ctrl + K</Code>}
                      />
                    </div>
                  </SpotlightProvider>
                </div>
              </div>
            </div>
          </Header>
        }
      >
        <Outlet />
      </AppShell>
      {/* For show the notifcation of home page */}
      <div style={{ display: "none" }}>
        <Home schemeDrawer={notificationDrawer} />
      </div>
    </div>
  );
}

export default Sidebar;
