import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/BreadCrumb"; // For breadcrumbs data import
import useStyles from "../../components/Style"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  ActionIcon,
  Select,
  Table,
  ScrollArea,
  Menu,
  NativeSelect,
  Drawer,
  Pagination,
  Grid,
  Tabs,
} from "@mantine/core"; //For import mantine required functions and theme // Mantine all required compoennts imports list
import {
  Search,
  Trash,
  Dots,
  Eye,
  FolderPlus,
  DotsCircleHorizontal,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import { CSVLink } from "react-csv"; // For export the data to excel format
import { Th, dataSearch, print } from "../../helpers/tableFunction"; // For table data functions
import { barcodePrint, dataSlice, selectFilter } from "../../helpers/common"; // Common fuctions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../assets/images/excel.png"; // Image for excel export
import pdf from "../../assets/images/pdf.png"; // Image for pdf exports
import { useNavigate } from "react-router-dom";

import { DatePicker } from "@mantine/dates";
import {
  handleGetProductHistory,
  handleGetProductStock,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis
import notificationHelper from "../../helpers/notification";
import BarcodeIcon from "../../assets/icons/BarcodeIcon";
import { ProductView } from "../../helpers/golden";
import GoldProduct from "../../helpers/GoldProduct";

function ProductStock() {
  var Barcode = require("react-barcode");
  // Mantine custome style use
  const { classes } = useStyles();
  let navigate = useNavigate();
  const modals = useModals();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [sortBy, setSortBy] = useState(null); // Seting the sortby table type
  const [reverseSortDirection, setReverseSortDirection] = useState(false); // For set the reverse sort direction
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  const [productModal, setProductModal] = useState(false);
  const [goldTitle, setGoldTitle] = useState("");
  const [goldValue, setGoldValue] = useState("");
  const [goldData, setGoldData] = useState("");
  //   For intial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the product data list
        const response = await handleGetProductStock();
        console.log(response);

        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data,
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    barcodeValue: "",
    productValue: "",
    groupList: [],
    editPOList: [],
    vendor: [],
    subGroupList: [],
    product: [],
    taxList: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
    deleteIndex2: 0,
  });

  // For form validation
  const formSearch = useForm({
    initialValues: {
      inventory_type: "",
    },
  });

  // Table data arrabnge by using function and loop throw each data rrange to table body
  const rows = sortedData.map((row, index) => (
    <>
      {row.max_total !== null ? (
        <>
          {row.stocks.inventory_type !== null ? (
            <tr key={row.label}>
              <td>{activePage * total - total + index + 1}</td>
              <td>
                <Group>
                  {row.label}
                  <ActionIcon
                    onClick={async () => {
                      const data = await handleGetProductHistory(row.value);
                      console.log(data);
                      const list = data.data.data;

                      setProductModal(true);
                      setGoldData(list);
                      setGoldTitle(row.label);
                      setGoldValue(row.value);
                    }}
                    ml={-10}
                    color="zevcore"
                    variant="transparent"
                  >
                    <DotsCircleHorizontal size={20} />
                  </ActionIcon>
                </Group>
              </td>
              <td>{Number(row.max_total / 1000)}</td>
              <td>{Number(row.max_remaining / 1000).toFixed(2)}</td>
              <td>--</td>
            </tr>
          ) : null}
        </>
      ) : null}
    </>
  ));

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Product Stock" />
      </Skeleton>
      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <Tabs
            color="zevcore"
            variant="outline"
            defaultValue="product_stock"
            onTabChange={(value) => navigate(`/product_packet_stock`)}
          >
            <Tabs.List>
              <Tabs.Tab value="product_stock">Bulk Product Stock</Tabs.Tab>
              <Tabs.Tab value="product_packet_stock">
                Unit Product Stock
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="product_stock" pt="xs">
              <ScrollArea>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* For search the table data input forms */}
                  <TextInput
                    variant="filled"
                    placeholder="Search by any field"
                    mb="md"
                    size="xs"
                    value={search}
                    icon={<Search size={14} />}
                    onChange={async (e) => {
                      // On change search ofr the data that is enter
                      setSearch(e.currentTarget.value);
                      setPage(1);
                      const datas = await dataSearch({
                        data: variables.data,
                        value: e.currentTarget.value,
                        activePage: activePage,
                        total: total,
                      });
                      setSortedData(datas);
                      setRefreshTable(new Date());
                    }}
                    sx={{ width: 250 }}
                  />
                  <div>
                    <Group spacing="xs">
                      {/* For export the the table data to pdf and excels */}
                      <Text>Exports :</Text>
                      <CSVLink
                        data={variables.data}
                        headers={[{ label: " PO Name", key: "label" }]}
                        filename="purchaseOrder.csv"
                        className={classes.pdfExcel}
                      >
                        <img
                          src={excel}
                          alt="excel"
                          width="25"
                          style={{ margin: "2px" }}
                        />
                      </CSVLink>
                      <button
                        className={classes.pdfExcel}
                        onClick={() =>
                          print({
                            data: variables.data,
                            list: ["label", "value"],
                            header: ["PO Name", "Id"],
                          })
                        }
                      >
                        <img
                          src={pdf}
                          alt="pdf"
                          width="19"
                          style={{ margin: "2px" }}
                        />
                      </button>
                      {/* Drawer open for adding new purchaseOrder data */}
                    </Group>
                  </div>
                </div>
                {/* Table data view */}
                <Table
                  horizontalSpacing="md"
                  verticalSpacing="xs"
                  className={classes.striped}
                >
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <Th>Sl.No</Th>
                      <Th>Product</Th>
                      <Th>Total Stock-In (KG's)</Th>
                      <Th>Total Remaining (KG's)</Th>
                      <Th>Wastage</Th>
                    </tr>
                  </thead>
                  {/* Table body defines from rows function */}
                  <tbody key={refreshTable}>
                    {rows.length > 0 ? (
                      rows
                    ) : (
                      <tr>
                        <td>
                          <Text weight={500} align="center">
                            Nothing found
                          </Text>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </ScrollArea>
            </Tabs.Panel>
          </Tabs>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
          {variables.data.length > 0 ? (
            <>
              <Text mt={5} align="right" size="sm" color="green">
                Last updated on:&nbsp;
                {new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")}
              </Text>
            </>
          ) : null}
        </Card>
      </Skeleton>
      <GoldProduct
        value={goldValue}
        title={goldTitle}
        list={goldData}
        productModal={productModal}
        setProductModal={setProductModal}
      />
    </div>
  );
}

export default ProductStock;
