import { useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Box,
  Code,
  MediaQuery,
  Burger,
  useMantineTheme,
  Group,
  TextInput,
  ActionIcon,
} from "@mantine/core";
import Icon from "../../assets/images/task.png";
import {
  Home,
  MoonStars,
  Sun,
  Search,
  Settings,
  UserExclamation,
  Logout,
  X,
  WindowMinimize,
  WindowMaximize,
  Refresh,
  Minus,
  Archive,
  ChartLine,
  User,
  Dashboard,
  RotateClockwise2,
  Cash,
  BoxMultiple,
  Download,
} from "tabler-icons-react";
import { IconSearch } from "@tabler/icons";
import { useLocalStorage, useClickOutside } from "@mantine/hooks";
import { useNavigate, Outlet } from "react-router-dom";
import { useModals } from "@mantine/modals";
import useStyles from "../../components/Style";
import {
  SpotlightProvider,
  useSpotlight,
  openSpotlight,
} from "@mantine/spotlight";

function SpotlightControl(props) {
  const spotlight = useSpotlight();
  return (
    <Group position="center" color="dark">
      <TextInput
        variant="filled"
        placeholder="Search"
        onClick={spotlight.openSpotlight}
        size="xs"
        width={50}
        // shortcut={["ctrl + k", "mod + K", "⌘ + K"]}
        icon={<IconSearch size={12} stroke={1.5} />}
        rightSectionWidth={70}
        // rightSection={<Code>Ctrl + K</Code>}
        styles={{ rightSection: { pointerEvents: "none" } }}
        mb="sm"
      />
    </Group>
  );
}

const scaleY = {
  in: { opacity: 1, transform: "scaleY(1)" },
  out: { opacity: 0, transform: "scaleY(0)" },
  common: { transformOrigin: "top" },
  transitionProperty: "transform, opacity",
};

// Side bar navigation list by array
const mockdata = [
  { label: "Dashboard", icon: Dashboard, link: "/" },
  {
    label: "Sales",
    icon: ChartLine,
    initiallyOpened: true,
    links: [
      { label: "Add Sale", link: "/add_sale" },
      { label: "Sales", link: "/sale" },
      { label: "Add Estimate", link: "/add_estimate" },
      { label: "Estimates", link: "/estimate" },
      { label: "KPI", link: "/kpi" },
      // { label: "HSN Report", link: "/hsn" },
    ],
  },
  {
    label: "Inventory",
    icon: Archive,
    initiallyOpened: true,
    links: [
      { label: "Vendors", link: "/vendors" },
      { label: "Stock In", link: "/stock_in" },
      { label: "Stock View", link: "/stock" },
      { label: "Purchase Order", link: "/po" },
    ],
  },
  {
    label: "Cash Ledger",
    icon: Box,
    initiallyOpened: true,
    links: [
      { label: "Cash Ledger ", link: "/cash" },
      { label: "Search Cash Ledger", link: "/search_cash" },
    ],
  },
  {
    label: "Products",
    icon: Box,
    initiallyOpened: true,
    links: [
      { label: "Groups", link: "/groups" },
      { label: "Products", link: "/products" },
    ],
  },
  { label: "Customers", icon: User, link: "/customers" },

  { label: "Settings", icon: Settings, link: "/manages" },
];

function Top() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [minimize, setMinimize] = useState(false);

  const handleReload = async (e) => {};
  const handleMinimize = async (e) => {};
  const handleUnmaximize = async (e) => {};
  const handleMaximize = async (e) => {};
  const handleHide = async (e) => {};
  const handleClose = async (e) => {};

  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const dark = colorScheme === "dark";
  //   const { classes } = useStyles();

  const modals = useModals();
  let navigate = useNavigate();

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={
        <Header
          height={32}
          py={2}
          style={{
            borderBottom: "0px",
          }}
          className="border-bottom noprint"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {dark ? (
                  <>
                    <img
                      src={Icon}
                      alt=""
                      style={{ height: "15px", margin: 3 }}
                    />
                    <Text size="xs" color="white" mt={2} weight={600}>
                      Zevcore - WAS
                    </Text>
                  </>
                ) : (
                  <>
                    <img
                      src={Icon}
                      alt=""
                      style={{ height: "15px", margin: 3 }}
                    />
                    <Text size="xs" mt={2} weight={600}>
                      Zevcore - WAS
                    </Text>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <ActionIcon
                  className=" no-drag"
                  color="dark"
                  onClick={() => handleReload()}
                  title="Page refresh"
                >
                  <RotateClockwise2 strokeWidth="0.8" size={20} />
                </ActionIcon>
                <ActionIcon
                  className="no-drag"
                  color="dark"
                  onClick={() => {
                    handleMinimize();
                  }}
                  title="Minimize"
                >
                  <Minus strokeWidth="0.8" size={15} />
                </ActionIcon>
                <ActionIcon
                  className="no-drag"
                  color="dark"
                  onClick={() => {
                    if (minimize === true) {
                      setMinimize(false);
                      handleUnmaximize();
                    } else {
                      setMinimize(true);
                      handleMaximize();
                    }
                  }}
                  title={minimize ? "Restore" : "Maximize"}
                >
                  {minimize ? (
                    <WindowMinimize
                      className="no-drag"
                      strokeWidth="0.8"
                      size={15}
                    />
                  ) : (
                    <WindowMaximize
                      className="no-drag"
                      strokeWidth="0.8"
                      size={15}
                    />
                  )}
                </ActionIcon>
                <ActionIcon
                  className="no-drag"
                  color="red"
                  onClick={() => {
                    modals.openConfirmModal({
                      title: "Confirm Close ",
                      children: (
                        <Text size="sm">
                          If you close you wont receive any notification or
                          minimize the application
                        </Text>
                      ),

                      labels: {
                        confirm: "Close",
                        cancel: "Minimize to tray",
                      },
                      confirmProps: { color: "red" },
                      onCancel: () => handleHide(),
                      onConfirm: () => {
                        localStorage.clear();
                        handleClose();
                      },
                    });
                  }}
                  title="Close Window"
                >
                  <X strokeWidth="0.8" size={15} />
                </ActionIcon>
              </div>
            </div>
          </div>
        </Header>
      }
    >
      <Outlet />
    </AppShell>
  );
}

export default Top;
