/* 
Project name: Zevgold APOS
Author: Zevcore Private Limited
Description: Zevcore Private Limited Zevgold APOS style css file
Created Date: 31/03/2022
Version: 1.0
Required: React and mantine
*/

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import {
  Avatar,
  Button,
  Center,
  Container,
  Grid,
  Group,
  Modal,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core"; //For import mantine required functions and theme //for import mantine required functions and theme
import "./invoice.css";
import {
  handleGetOneInvoice,
  handleGetAccount,
  handleGetAddress,
  handleGetBank,
  handleGetCustomer,
  handlePathURL,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis
import { useLocalStorage } from "@mantine/hooks";
import LogoW from "../../assets/images/logoW.png";
import { CreditCard, File, Printer } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { useModals } from "@mantine/modals";
import axios from "axios";

const converter = require("number-to-words");
function PrintInvoice() {
  const [token, setToken] = useState(localStorage.getItem("token")); //get saved local storage data
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [imageURL, setImageURL] = useState("public/images/product/");
  const [URL, setURL] = useState(process.env.REACT_APP_BACKEND_URL);
  const [URLFILE, setURLFILE] = useState(process.env.REACT_APP_FILE);
  const [PROFILE, setPROFILE] = useState(process.env.REACT_APP_PROFILE_URL);
  const [bank, setBank] = useState("");
  const [address, setAddress] = useState("");
  let navigate = useNavigate();
  const [type, setType] = useLocalStorage({
    key: "printer-type",
    defaultValue: "1",
  });

  const [data, setData] = useState("");
  const [note, setNote] = useState([]);
  const [payment, setPayment] = useState([]);
  const [five, setFive] = useState("");
  const [twelve, setTwelve] = useState("");
  const [fifteen, setFifteen] = useState("");
  const [customer, setCustomer] = useState("");
  const [company, setCompany] = useState("");

  const [hideIndex, setHideIndex] = useState(true);
  const [paymentUpdate, setPaymentUpdate] = useState(false);

  const [noteUpdate, setNoteUpdate] = useState(false);
  const [hideDiscount, setHideDiscount] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(0);
  const modals = useModals();

  const formCustomer = useForm({
    initialValues: {
      amount: "",
      paymentMethod: "",
      paymentStatus: "",
      amountPaid: "",
      note: "",
    },
  });

  const params = useParams();
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        const reg = {
          value: params.value,
        };
        //   For get all the group data list
        const response = await handleGetOneInvoice(params.id);

        // On Respose setting the data to variable
        if (response.status === 200) {
          setData(response.data.data);
        }

        var filter = response.data.data.invoice_items;
        var fives = filter.filter((x) => x.tax == "5");
        setFive(fives);

        var twelves = filter.filter((x) => x.tax == "12");
        setTwelve(twelves);
        var fifteeens = filter.filter((x) => x.tax == "18");
        setFifteen(fifteeens);

        const response2 = await handleGetAccount();
        if (response2.status === 200) {
          setCompany(response2.data);
        }

        const response4 = await handleGetAddress();

        // On Respose setting the data to variable
        if (response4.status === 200) {
          if (
            response.data.data.address != null &&
            response.data.data.address != "" &&
            typeof response.data.data.address != "undefined"
          ) {
            var datas = response4.data.data,
              datas = datas.find(
                (img) => img.label === response.data.data.address
              );
            setAddress(datas);
          }
        }

        const response5 = await handleGetBank();

        // On Respose setting the data to variable
        if (response5.status === 200) {
          if (
            response.data.data.bank_name != null &&
            response.data.data.bank_name != "" &&
            typeof response.data.data.bank_name != "undefined"
          ) {
            var datas = response5.data.data,
              datas = datas.find(
                (img) => img.label === response.data.data.bank_name
              );
            setBank(datas);
          }
        }

        const response6 = await handleGetCustomer();

        // On Respose setting the data to variable
        if (response6.status === 200) {
          if (
            response.data.data.customer_id != null &&
            response.data.data.customer_id != "" &&
            typeof response.data.data.customer_id != "undefined"
          ) {
            var datas = response6.data.data,
              datas = datas.find(
                (img) => img.label === response.data.data.customer_id
              );
            setCustomer(datas);
          }
        }
        // const responseURL = await handlePathURL();
        // if (responseURL.status == 200) {
        //   setImageURL(responseURL.data.url);
        // }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const price_in_words = (price) => {
    var sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    if (((price += ""), isNaN(parseInt(price)))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
        switch (
          ((digit = price[digitIdx] - 0),
          (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
        }
      str = words.reverse().join("");
    } else if (parseInt(price) == 0) {
      str = "Zero";
    } else str = "";
    return str;
  };
  const price_in_words2 = (price) => {
    var split = price.toString().split(".");
    var nonDecimal = split[0];
    var decimal = split[1];

    return (
      `INR` +
      price_in_words(Number(nonDecimal)) +
      ` & 
` +
      price_in_words(Number(decimal)) +
      ` paise`
    );
  };
  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          textAlign: "right",
        }}
        className="noprint"
      >
        <Button
          type="submit"
          color="zevcore"
          variant="outline"
          mt={15}
          onClick={() => navigate("/sale")}
        >
          Back to sales
        </Button>
      </div>
      <div>
        {data != "" ? (
          // For view the pdf invoice page
          <Container mt={10} mb={15}>
            <div
              className="print_border noprint"
              style={{ textAlign: "center" }}
            ></div>
            {/* For view tax-invoice id and date */}
            <div className="print_border" style={{ textAlign: "center" }}>
              <Text weight={600} size="lg">
                TAX-INVOICE
              </Text>
            </div>
            <div className="print_border">
              <Grid>
                <Grid.Col span={7}>
                  <Center>
                    <img src={LogoW} alt="" style={{ width: "40%" }} />
                  </Center>
                </Grid.Col>
                <Grid.Col span={5}>
                  <Text color="#A64D79" weight={700} size="lg">
                    Imprulife Venture Pvt Ltd
                  </Text>
                  <Text size="xs">
                    Address : 3-2-36E10, 2nd floor, Niriksha Arcade, Old DC
                    Office Road, Brahmagiri Circle Udupi 576101, Karnataka,
                    India Phone Number-: +91-9880567755 / +91- 9850858483,
                    Email-:info@imprulife.com, PAN : AAECI5553R, GSTIN:
                    29AAECI5553R1ZR, CIN: U74999KA2017PTC104433
                  </Text>
                </Grid.Col>
              </Grid>
            </div>
            <div className="print_border">
              <Grid>
                <Grid.Col span={6}>
                  <Text weight={600} size="lg">
                    INVOICE #
                  </Text>
                </Grid.Col>
                <Grid.Col span={6} style={{ textAlign: "right" }}>
                  <Text weight={600} size="lg">
                    {data.invoice_number}
                  </Text>
                </Grid.Col>
              </Grid>
            </div>
            {/* For view the customer name and address */}
            <div className="print_border">
              <Grid>
                <Grid.Col span={6}>
                  <p style={{ margin: 0, fontSize: 12 }}>
                    <span style={{ fontWeight: 600 }}>Customer Name :</span>{" "}
                    {/* {customer.label} */}
                  </p>
                  <p style={{ margin: 0, fontSize: 12 }}>
                    <span style={{ fontWeight: 600 }}>Customer Address :</span>{" "}
                    {/* {customer.address} */}
                  </p>
                  <p style={{ margin: 0, fontSize: 12 }}>
                    <span style={{ fontWeight: 600 }}>Email :</span>{" "}
                    {/* {customer.email1} , {customer.email2} */}
                  </p>
                  <p style={{ margin: 0, fontSize: 12 }}>
                    <span style={{ fontWeight: 600 }}>Phone Number :</span>{" "}
                    {/* {customer.phone_number} */}
                  </p>
                  <p style={{ margin: 0, fontSize: 12 }}>
                    <span style={{ fontWeight: 600 }}>GSTIN :</span>{" "}
                    {/* {customer.gstin} */}
                  </p>
                </Grid.Col>
                <Grid.Col span={6} style={{ textAlign: "right" }}>
                  <Text>
                    Date :{" "}
                    {new Date(data.invoice_date).toLocaleDateString("en-IN")}
                  </Text>
                </Grid.Col>
              </Grid>
            </div>
            <div className="print_border">
              <div>
                {/* Table view */}
                <table className="print_table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>HSN/ SAC</th>
                      <th>Qty(Pcs)</th>
                      <th>Price</th>
                      <th>Discount(%)</th>
                      {data.cgst !== null ? (
                        <>
                          <th>%</th>
                          <th>CGST</th>
                          <th>%</th>
                          <th>SGST</th>
                        </>
                      ) : null}
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* For view saled product list in loop with quantity and tax amount */}
                    {data.invoice_items.map((row, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{row.product_name}</td>
                        <td>{row.hsn}</td>
                        <td>{row.count}</td>
                        <td>{row.rate}</td>
                        <td>{row.discount}</td>
                        {data.cgst !== null ? (
                          <>
                            <td>{Number(row.tax) / 2}</td>
                            <td>{Number(data.cgst)}</td>
                            <td>{Number(row.tax) / 2}</td>
                            <td>{Number(data.sgst)}</td>
                          </>
                        ) : null}
                        <td>{row.amount}</td>
                      </tr>
                    ))}
                    {/* For total taxable value */}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {data.cgst !== null ? (
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>Taxable Value</td>
                          <td>{data.sub_total}</td>
                        </>
                      ) : (
                        <>
                          <td>Total</td>
                          <td>{data.sub_total}</td>
                        </>
                      )}
                    </tr>
                    {data.cgst !== null ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Taxes</td>
                        <td>{Number(data.cgst) + Number(data.sgst)}</td>
                      </tr>
                    ) : null}
                    {data.discount !== null ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Discount</td>
                        <td>{Number(data.discount)}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td>{data.total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* For view the company bank details only for gst invoice */}
            {data.cgst !== null ? (
              <div>
                <div className="print_border">
                  <Grid>
                    <Grid.Col span={5}>
                      <Text weight={600} size="lg">
                        Bank Details :
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={7}>
                      {" "}
                      <Text weight={600} size="lg">
                        Tax Details :
                      </Text>
                    </Grid.Col>
                  </Grid>
                </div>
                <div className="print_border">
                  <Grid>
                    <Grid.Col span={5}>
                      <p style={{ margin: 0, fontSize: 10 }}>
                        {" "}
                        ACCOUNT NAME : IMPRULIFE VENTURE PRIVATE LIMITED <br />{" "}
                        ACCOUNT NUMBER : 0760261001208 (O/D ACCOUNT) <br /> BANK
                        NAME-: CANARA BANK <br /> IFSC CODE-: CNRB0000760 <br />{" "}
                        SWIFT CODE-: CNRBINBBRFM <br /> BRANCH-: Uppoor <br />{" "}
                        BRANCH ADDRESS-: NEELIMA, KG ROAD, THENKABETTU PO
                        UPPOOR, UDUPI DIST, KG ROAD, <br /> THENKABETTU PO
                        UPPOOR, UDUPI DIST, KARNATAKA -576125, INDIA
                      </p>
                    </Grid.Col>
                    <Grid.Col span={7}>
                      {/* Tablefor view the tax of overall sale with percetnage wise */}
                      <table className="zc_table2">
                        <thead>
                          <tr>
                            <th>Tax%</th>
                            <th>Taxable</th>
                            <th>SGST%</th>
                            <th>Amt</th>
                            <th>CGST%</th>
                            <th>Amt</th>
                            <th>IGST</th>
                            <th>Amt</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>5%</td>
                            <td>
                              {" "}
                              {parseFloat(
                                five.reduce(function (sum, current) {
                                  return +Number(sum) + Number(current.amount);
                                }, 0)
                              ).toFixed(2)}
                            </td>
                            <td>2.5%</td>
                            <td>
                              {" "}
                              {(
                                parseFloat(
                                  five.reduce(function (sum, current) {
                                    return (
                                      +Number(sum) +
                                      Number(current.count) *
                                        Number(current.tax_rate)
                                    );
                                  }, 0)
                                ) / 2
                              ).toFixed(2)}
                            </td>
                            <td>2.5%</td>
                            <td>
                              {" "}
                              {(
                                parseFloat(
                                  five.reduce(function (sum, current) {
                                    return (
                                      +Number(sum) +
                                      Number(current.count) *
                                        Number(current.tax_rate)
                                    );
                                  }, 0)
                                ) / 2
                              ).toFixed(2)}
                            </td>
                            <td>2.5%</td>
                            <td>
                              {" "}
                              {(
                                parseFloat(
                                  five.reduce(function (sum, current) {
                                    return (
                                      +Number(sum) +
                                      Number(current.count) *
                                        Number(current.tax_rate)
                                    );
                                  }, 0)
                                ) / 2
                              ).toFixed(2)}
                            </td>
                            <td>
                              {" "}
                              {parseFloat(
                                five.reduce(function (sum, current) {
                                  return (
                                    +Number(sum) +
                                    Number(current.count) *
                                      Number(current.tax_rate)
                                  );
                                }, 0)
                              ).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td>12%</td>
                            <td>
                              {" "}
                              {parseFloat(
                                twelve.reduce(function (sum, current) {
                                  return +Number(sum) + Number(current.amount);
                                }, 0)
                              ).toFixed(2)}
                            </td>
                            <td>6%</td>
                            <td>
                              {" "}
                              {(
                                parseFloat(
                                  twelve.reduce(function (sum, current) {
                                    return (
                                      +Number(sum) +
                                      Number(current.count) *
                                        Number(current.tax_rate)
                                    );
                                  }, 0)
                                ) / 2
                              ).toFixed(2)}
                            </td>
                            <td>6%</td>
                            <td>
                              {" "}
                              {(
                                parseFloat(
                                  twelve.reduce(function (sum, current) {
                                    return (
                                      +Number(sum) +
                                      Number(current.count) *
                                        Number(current.tax_rate)
                                    );
                                  }, 0)
                                ) / 2
                              ).toFixed(2)}
                            </td>
                            <td>6%</td>
                            <td>
                              {" "}
                              {(
                                parseFloat(
                                  twelve.reduce(function (sum, current) {
                                    return (
                                      +Number(sum) +
                                      Number(current.count) *
                                        Number(current.tax_rate)
                                    );
                                  }, 0)
                                ) / 2
                              ).toFixed(2)}
                            </td>
                            <td>
                              {" "}
                              {parseFloat(
                                twelve.reduce(function (sum, current) {
                                  return (
                                    +Number(sum) +
                                    Number(current.count) *
                                      Number(current.tax_rate)
                                  );
                                }, 0)
                              ).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td>18%</td>
                            <td>
                              {" "}
                              {parseFloat(
                                fifteen.reduce(function (sum, current) {
                                  return +Number(sum) + Number(current.amount);
                                }, 0)
                              ).toFixed(2)}
                            </td>
                            <td>9%</td>
                            <td>
                              {" "}
                              {(
                                parseFloat(
                                  fifteen.reduce(function (sum, current) {
                                    return (
                                      +Number(sum) +
                                      Number(current.count) *
                                        Number(current.tax_rate)
                                    );
                                  }, 0)
                                ) / 2
                              ).toFixed(2)}
                            </td>
                            <td>9%</td>
                            <td>
                              {" "}
                              {(
                                parseFloat(
                                  fifteen.reduce(function (sum, current) {
                                    return (
                                      +Number(sum) +
                                      Number(current.count) *
                                        Number(current.tax_rate)
                                    );
                                  }, 0)
                                ) / 2
                              ).toFixed(2)}
                            </td>
                            <td>9%</td>
                            <td>
                              {" "}
                              {(
                                parseFloat(
                                  fifteen.reduce(function (sum, current) {
                                    return (
                                      +Number(sum) +
                                      Number(current.count) *
                                        Number(current.tax_rate)
                                    );
                                  }, 0)
                                ) / 2
                              ).toFixed(2)}
                            </td>
                            <td>
                              {" "}
                              {parseFloat(
                                fifteen.reduce(function (sum, current) {
                                  return (
                                    +Number(sum) +
                                    Number(current.count) *
                                      Number(current.tax_rate)
                                  );
                                }, 0)
                              ).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid.Col>
                  </Grid>
                </div>
              </div>
            ) : null}

            <div className="print_border">
              <Text weight={600}>Note:</Text>
              <Text>Goods once sold are not returnable & non refundable</Text>
            </div>
            <div style={{ padding: 10 }}>
              <Text>CUSTOMER SIGNATURE</Text>
              <Text>&nbsp;</Text>
              <Text>&nbsp;</Text>
            </div>
            <div
              style={{
                textAlign: "right",
              }}
              className="noprint"
            >
              <div
                className="print_border"
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div>
                  <Button
                    type="submit"
                    color="black"
                    mt={15}
                    onClick={() => {
                      window.print();
                    }}
                  >
                    <Printer size={18} /> &nbsp; Print
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        ) : null}
      </div>
    </div>
  );
}

export default PrintInvoice;
