import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/BreadCrumb"; // For breadcrumbs data import
import useStyles from "../../components/Style"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  ActionIcon,
  Select,
  Table,
  ScrollArea,
  Menu,
  NativeSelect,
  Drawer,
  Pagination,
  Grid,
  Tabs,
} from "@mantine/core"; //For import mantine required functions and theme // Mantine all required compoennts imports list
import { Search, Trash, Dots, Eye } from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import { CSVLink } from "react-csv"; // For export the data to excel format
import { Th, dataSearch, print } from "../../helpers/tableFunction"; // For table data functions
import { barcodePrint, dataSlice, selectFilter } from "../../helpers/common"; // Common fuctions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../assets/images/excel.png"; // Image for excel export
import pdf from "../../assets/images/pdf.png"; // Image for pdf exports
import { useNavigate } from "react-router-dom";

import { DatePicker } from "@mantine/dates";
import {
  handleGetGroup,
  handleGetProduct,
  handleGetPacketStock,
  handleDeletePacketStock,
  handleAddPacketStock,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis
import notificationHelper from "../../helpers/notification";
import BarcodeIcon from "../../assets/icons/BarcodeIcon";

function PacketStock() {
  var Barcode = require("react-barcode");
  // Mantine custome style use
  const { classes } = useStyles();
  const modals = useModals();
  let navigate = useNavigate();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [sortBy, setSortBy] = useState(null); // Seting the sortby table type
  const [reverseSortDirection, setReverseSortDirection] = useState(false); // For set the reverse sort direction
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table

  // For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the product data list
        const response = await handleGetPacketStock();
        // On Response setting the data to variable
        if (response.status === 200) {
          console.log(response.data);
          setVariables({
            ...variables,
            data: response.data.data,
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }

        // On Response setting the data to variable
        const response2 = await handleGetGroup();

        if (response2.status === 200) {
          const listGroup = await selectFilter({
            data: response2.data.data,
          });

          setVariables((variables) => {
            return {
              ...variables,
              groupList: listGroup,
              skeletonLoading: false,
            };
          });
        }

        const response5 = await handleGetProduct();
        if (response5.status === 200) {
          const listProduct = await response5.data.data.map((data) => ({
            ...data,
            value: data.value.toString(),
            label: data.label.toString(),
          }));

          listProduct.forEach(function (item) {
            delete item.createdAt;
            delete item.updatedAt;
            delete item.category;
          });

          setVariables((variables) => {
            return {
              ...variables,
              product: listProduct,
              skeletonLoading: false,
            };
          });
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    groupList: [],
    editPOList: [],
    vendor: [],
    product: [],
    taxList: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
    deleteIndex2: 0,
  });

  // For adding data form Add data  Form
  const form = useForm({
    initialValues: {
      details: [
        {
          category_id: "1",
          product_id: "",
          packet_quantity: 0,
          stock_quantity: 0,
          packet_price: 0,
        },
      ],
    },
  });

  // For po add more adding extra row data for po
  const fields = form.values.details.map((_, index) => (
    <tr key={index}>
      <td>
        <Text>{index + 1}</Text>
      </td>
      <td>
        <Select
          variant="filled"
          classNames={{ item: classes.selectItem }}
          searchable
          required
          size="xs"
          placeholder="Select Category"
          data={variables.groupList}
          {...form.getInputProps(`details.${index}.category_id`)}
        />
      </td>
      <td>
        <Select
          variant="filled"
          classNames={{ item: classes.selectItem }}
          searchable
          required
          size="xs"
          placeholder="Select Product"
          data={variables.product.filter(
            (raw) =>
              Number(raw.category_id) ===
              Number(form.values.details[index].category_id)
          )}
          {...form.getInputProps(`details.${index}.product_id`)}
        />
      </td>

      <td>
        <TextInput
          variant="filled"
          mr={20}
          required
          size="xs"
          placeholder="Quantity/Packet"
          {...form.getInputProps(`details.${index}.packet_quantity`)}
        />
      </td>

      <td>
        <TextInput
          variant="filled"
          mr={20}
          required
          size="xs"
          placeholder="Stock Quantity"
          {...form.getInputProps(`details.${index}.stock_quantity`)}
        />
      </td>

      <td>
        <TextInput
          variant="filled"
          mr={20}
          required
          size="xs"
          placeholder="Packet Price"
          {...form.getInputProps(`details.${index}.packet_price`)}
        />
      </td>

      <td>
        <ActionIcon
          color="red"
          mr={20}
          variant="hover"
          onClick={() => form.removeListItem("details", index)}
        >
          <Trash size={16} />
        </ActionIcon>
      </td>
    </tr>
  ));

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <>
      {row.max_remaining_quantity !== null ? (
        <>
          <tr key={row.value}>
            <td>{activePage * total - total + index + 1}</td>
            <td>{row.product.label}</td>
            <>
              {row.stock_id === null ? (
                <td>--</td>
              ) : (
                <td>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <BarcodeIcon style={{ width: 20 }} />
                    <Text size="xs" ml={5} mt={5}>
                      {row.stock.barcode}
                    </Text>
                  </div>
                </td>
              )}

              {row.vendor === null ? <td>--</td> : <td>{row.vendor.label}</td>}
            </>
            <td
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setVariables({
                  ...variables,
                  barcodeValue: row.barcode,
                  productValue: row.product.label,
                });
                barcodePrint(row.barcode, row.product.label);
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <BarcodeIcon style={{ width: 20 }} />
                <Text size="xs" ml={5} mt={5}>
                  {row.barcode}
                </Text>
              </div>
            </td>
            <td>{row.stock_quantity}</td>
            <td>{row.remaining_stock}</td>
            <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
            <td justifycontent="right" align="right">
              <Menu shadow="sm" size="xs">
                <Menu.Target>
                  <ActionIcon
                    color="zevcore"
                    type="button"
                    style={{ marginLeft: 10 }}
                    size="xs"
                  >
                    <Dots />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => openConfirmModal(row.value)}
                    icon={<Trash size={14} />}
                  >
                    Mark Wastage
                  </Menu.Item>
                </Menu.Dropdown>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => openConfirmModal(row.value)}
                    icon={<Trash size={14} />}
                  >
                    Delete
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </td>
          </tr>
        </>
      ) : null}
    </>
  ));

  const handleAdd = async (e) => {
    console.log(e);
    setVariables({ ...variables, submitLoading: false });

    const response = await handleAddPacketStock(e);

    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Packet Stock added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  //For delete confirm modal show Delete
  const openConfirmModal = (e) => {
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Do you want to delete this po value",
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };
  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeletePacketStock(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Data deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        submitLoading: false,
        data: filter,
      });
      const datas = dataSlice({
        data: filter,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Stock" />
      </Skeleton>

      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <Tabs
            color="zevcore"
            variant="outline"
            defaultValue="packetStock"
            onTabChange={(value) => navigate(`/stock`)}
          >
            <Tabs.List>
              <Tabs.Tab value="stock">Bulk Stock</Tabs.Tab>
              <Tabs.Tab value="packetStock">Unit Stock</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="packetStock" pt="xs">
              <ScrollArea>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* For search the table data input forms */}
                  <TextInput
                    variant="filled"
                    placeholder="Search by any field"
                    mb="md"
                    size="xs"
                    value={search}
                    icon={<Search size={14} />}
                    onChange={async (e) => {
                      // On change search ofr the data that is enter
                      setSearch(e.currentTarget.value);
                      setPage(1);
                      const datas = await dataSearch({
                        data: variables.data,
                        value: e.currentTarget.value,
                        activePage: activePage,
                        total: total,
                      });
                      setSortedData(datas);
                      setRefreshTable(new Date());
                    }}
                    sx={{ width: 250 }}
                  />
                  <div>
                    <Group spacing="xs">
                      {/* For export the the table data to pdf and excels */}
                      <Text>Exports :</Text>
                      <CSVLink
                        data={variables.data}
                        headers={[{ label: " PO Name", key: "label" }]}
                        filename="packet_stock.csv"
                        className={classes.pdfExcel}
                      >
                        <img
                          src={excel}
                          alt="excel"
                          width="25"
                          style={{ margin: "2px" }}
                        />
                      </CSVLink>
                      <button
                        className={classes.pdfExcel}
                        onClick={() =>
                          print({
                            data: variables.data,
                            list: ["label", "value"],
                            header: ["PO Name", "Id"],
                          })
                        }
                      >
                        <img
                          src={pdf}
                          alt="pdf"
                          width="19"
                          style={{ margin: "2px" }}
                        />
                      </button>
                      {/* Drawer open for adding new packet Stock data */}
                      <Button
                        variant="outline"
                        color="zevcore"
                        size="xs"
                        onClick={() =>
                          setVariables({ ...variables, addDrawer: true })
                        }
                      >
                        + Add Packet Stock
                      </Button>
                    </Group>
                  </div>
                </div>
                {/* Table data view */}
                <Table
                  horizontalSpacing="md"
                  verticalSpacing="xs"
                  className={classes.striped}
                >
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <Th>Sl.No</Th>
                      <Th>Product</Th>
                      <Th>Bulk Batch No.</Th>
                      <Th>Vendor</Th>
                      <Th>Barcode</Th>
                      <Th>Total Stock (Unit's)</Th>
                      <Th>Remaining (Unit's)</Th>
                      <Th>Date</Th>
                      <Th>Action</Th>
                    </tr>
                  </thead>
                  {/* Table body defines from rows function */}
                  <tbody key={refreshTable}>
                    {rows.length > 0 ? (
                      rows
                    ) : (
                      <tr>
                        <td>
                          <Text weight={500} align="center">
                            Nothing found
                          </Text>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </ScrollArea>
            </Tabs.Panel>
          </Tabs>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
          {variables.data.length > 0 ? (
            <>
              <Text mt={5} align="right" size="sm" color="green">
                Last updated on:&nbsp;
                {new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")}
              </Text>
            </>
          ) : null}
        </Card>
        <div>
          <div
            id={variables.barcodeValue}
            style={{ position: "absolute", margin: 25 }}
          >
            <Barcode
              value={variables.barcodeValue}
              height={20}
              width={2}
              displayValue={false}
            />
            <p>{variables.productValue}</p>
          </div>
        </div>
      </Skeleton>

      {/* Data adding form*/}
      <Drawer
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title="Add Packet Stock"
        classNames={{
          header: classes.header,
          drawer: classes.drawer,
          closeButton: classes.closeButton,
        }}
        size="100%"
        position="right"
      >
        <ScrollArea
          style={{
            height: "90vh",
          }}
          type="scroll"
          offsetScrollbars
          scrollbarSize={5}
        >
          <div style={{ padding: 24 }}>
            {/* For adding new po data list */}
            <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
              <Group position="right" mt={10}>
                {/* On click more adding extra row here */}
                <Button
                  color="zevcore"
                  size="xs"
                  mb="sm"
                  onClick={() =>
                    form.insertListItem("details", {
                      product_id: "",
                      barcode: "",
                      cost_per_item: "",
                      total_item: 0,
                      date: new Date(),
                    })
                  }
                >
                  Add Item
                </Button>
              </Group>
              <ScrollArea
                className="add_more_scroll"
                scrollbarSize={10}
                color="gold"
                style={{
                  height: "60vh",
                }}
              >
                {fields.length > 0 ? (
                  <>
                    <Table
                      horizontalSpacing="md"
                      verticalSpacing="xs"
                      className={classes.striped}
                    >
                      <thead>
                        <tr>
                          <Th>Sl.No</Th>
                          <Th>Category</Th>
                          <Th>Product</Th>
                          <Th>Quantity/ Packet(Grams)</Th>
                          <Th>Stock Quantity</Th>
                          <Th>Selling Price/Packet</Th>
                          <Th>Action</Th>
                        </tr>
                      </thead>
                      <tbody key={refreshTable}>{fields}</tbody>
                    </Table>
                  </>
                ) : null}
              </ScrollArea>
              <Group position="right" mt="md">
                <Button type="submit" color="zevcore">
                  Submit
                </Button>
              </Group>
            </form>
          </div>
        </ScrollArea>
      </Drawer>
      {/* Data adding form */}
    </div>
  );
}

export default PacketStock;
