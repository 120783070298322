import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../components/BreadCrumb"; // For breadcrumbs data import
import useStyles from "../../components/Style"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Drawer,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  Menu,
  ActionIcon,
  Progress,
  Paper,
  Select,
} from "@mantine/core"; //For import mantine required functions and theme // Mantine all required compoennts imports list
import {
  Cash,
  CloudUpload,
  Dots,
  Eye,
  Pencil,
  Search,
  Trash,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import { CSVLink } from "react-csv"; // For export the data to excel format
import {
  handleDeleteInvoice,
  handleGetCustomer,
  handleGetInvoice,
  handleGetOneInvoice,
  handleGetTwoDate,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Import for axios requests list for this pages
import notificationHelper from "../../helpers/notification"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import { dataSlice, onDownload, selectFilter } from "../../helpers/common"; // Common fuctions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../assets/images/excel.png"; // Image for excel export
import pdf from "../../assets/images/pdf.png"; // Image for pdf exports
import { useInterval } from "@mantine/hooks";
import readXlsxFile from "read-excel-file";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components
import PaymentInvoice from "../../helpers/PaymentInvoice";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import { DateRangePicker } from "@mantine/dates";

function WeeklySales() {
  // Mantine custome style use
  const { classes } = useStyles();
  const modals = useModals();
  let navigate = useNavigate();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [sortBy, setSortBy] = useState(null); // Seting the sortby table type
  const [reverseSortDirection, setReverseSortDirection] = useState(false); // For set the reverse sort direction
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  const [firstDate, setFirstDate] = useState("");
  // Product golden
  const [paymentModal, setPaymentModal] = useState(false);
  const [goldTitle, setGoldTitle] = useState("");
  const [goldValue, setGoldValue] = useState("");
  const [goldData, setGoldData] = useState("");
  const [dateRange, setDateRange] = useState([
    new Date(),
    new Date(new Date() + 7),
  ]);
  // Setting the variables data list here

  const [years, setYears] = useState([
    // { value: "2019", label: "2019" },
    // { value: "2020", label: "2020" },
    // { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
    { value: "2028", label: "2028" },
    { value: "2029", label: "2029" },
    { value: "2030", label: "2030" },
    { value: "2031", label: "2031" },
    { value: "2032", label: "2032" },
  ]);

  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    customer: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  // For form validation
  const form = useForm({
    initialValues: {
      name: "",
    },
    validate: {
      name: (value) => (value.length < 1 ? "Sale name is required" : null),
    },
  });

  //   For edit form data validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      editName: "",
    },
    validate: {
      editName: (value) => (value.length < 1 ? "Sale name is required" : null),
    },
  });

  //   For intial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      console.log(paymentModal);
      if (mounted) {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1); //get first day of the year
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0); //get last date date of the year

        // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1); //get first day of the year
        // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        //   For get all the invoice data list
        const req = {
          date1: new Date(firstDay),
          date2: new Date(lastDay),
        };
        const response = await handleGetTwoDate(req);

        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables((variables) => {
            return {
              ...variables,
              data: response.data.data[0],
              skeletonLoading: false,
            };
          });
          const datas = dataSlice({
            data: response.data.data[0],
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }

        const response2 = await handleGetCustomer();
        // On Response setting the data to variable
        if (response2.status === 200) {
          const listGroup = await selectFilter({
            data: response2.data.data,
          });

          setVariables((variables) => {
            return {
              ...variables,
              customer: listGroup,
            };
          });
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, [paymentModal]);

  // Add new invoice data
  const AddInvoice = async (e) => {
    const req = {
      date1: new Date(dateRange[0]),
      date2: new Date(dateRange[1]),
    };
    setVariables({ ...variables, submitLoading: true });
    const response = await handleGetTwoDate(req);
    // Check for response for actions
    if (response.status === 200) {
      setVariables((variables) => {
        return {
          ...variables,
          data: response.data.data[0],
          submitLoading: false,
        };
      });
      const datas = dataSlice({
        data: response.data.data[0],
        page: 1,
        total: 10,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Table data arrabnge by using function and loop throw each data rrange to table body
  const rows = sortedData.map((row, index) => (
    <tr key={row.label}>
      <td>{activePage * total - total + index + 1}</td>
      <td>{new Date(row.invoice_date).toLocaleDateString("en-UK")}</td>
      <td>{row.invoice_number}</td>
      <td>{row.customer_id}</td>
      <td>{row.discount}</td>
      <td>{row.sub_total}</td>
      <td>{row.cgst}</td>
      <td>{row.sgst}</td>
      <td>{row.total}</td>
      <td>{row.paid}</td>
      <td>{row.balance}</td>
      {/* For action drop down edit and delete the data */}
      <td justifycontent="right" align="right">
        <Menu shadow="sm" size="xs">
          <Menu.Target>
            <ActionIcon
              color="zevcore"
              type="button"
              style={{ marginLeft: 10 }}
              size="xs"
            >
              <Dots />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => navigate("/print_invoice/" + row.value)}
              icon={<Eye size={14} />}
            >
              View
            </Menu.Item>
            <Menu.Item
              onClick={async () => {
                const reg = {
                  id: row.invoice_number,
                };
                const data = await handleGetOneInvoice(reg);
                console.log(data);
                const list = data.data.data;
                setPaymentModal(true);
                setGoldData(list);
                setGoldTitle(row.invoice_number);
                setGoldValue(row.value);
              }}
              icon={<Cash size={14} />}
            >
              Payment
            </Menu.Item>
            <Menu.Item
              onClick={() => openConfirmModal(row.value)}
              icon={<Trash size={14} />}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </td>
    </tr>
  ));

  //For delete confirm modal show Delete
  const openConfirmModal = (e) => {
    console.log(e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Do you want to delete this sale value",
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };
  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteInvoice(e);
    // Check the response for notification and actions

    console.log(response);

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Sale deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        submitLoading: false,
        data: filter,
      });
      const datas = dataSlice({
        data: filter,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  //   set editable data to form Edit form
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    formEdit.setFieldValue("value", datas.value);
    formEdit.setFieldValue("editName", datas.label);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Weekly Sales Report" />
      </Skeleton>

      <Paper mb={10} p={15} className="border">
        <Group>
          <DateRangePicker
            variant="filled"
            label="Pick Week"
            zIndex={5}
            fullWidth
            placeholder="Pick dates range"
            value={dateRange}
            minDate={dayjs(new Date(firstDate))
              .startOf("year")
              .add(0, "days")
              .toDate()}
            maxDate={dayjs(new Date())
              .endOf("year")
              .subtract(0, "days")
              .toDate()}
            onChange={setDateRange}
            mb="lg"
          />
          <Button
            loading={variables.submitLoading}
            onClick={() => AddInvoice()}
            mt={6}
            leftIcon={<Search size={14} />}
          >
            Search
          </Button>
        </Group>
      </Paper>

      {/* Main start here */}
      <Skeleton
        radius="md"
        className="CardOverride"
        visible={variables.skeletonLoading}
      >
        <Card className="border CardOverride">
          <div>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="CardOverride"
            >
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search by any field"
                mb="md"
                size="xs"
                value={search}
                icon={<Search size={14} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "Invoice ID", key: "invoice_number" },
                      { label: "Payment Type", key: "bill_type" },
                      { label: "Invoice Date", key: "invoice_date" },
                      { label: "Customer", key: "customer_id" },
                      { label: "Sub-Total", key: "sub_total" },
                      { label: "CGST", key: "cgst" },
                      { label: "SGST", key: "sgst" },
                      { label: "Total", key: "total" },
                      { label: "Balance", key: "balance" },
                    ]}
                    filename="sale.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  <button
                    className={classes.pdfExcel}
                    onClick={() =>
                      print({
                        data: variables.data,
                        list: [
                          "invoice_number",
                          "bill_type",
                          "invoice_date",
                          "customer_id",
                          "sub_total",
                          "cgst",
                          "sgst",
                          "total",
                          "balance",
                        ],
                        header: [
                          "Invoice ID",
                          "Payment Type",
                          "Invoice Date",
                          "Customer",
                          "Sub-Total",
                          "CGST",
                          "SGST",
                          "Total",
                          "Balance",
                        ],
                        type: "portrait", // Add change if u want landscape or portrait
                        name: "sales",
                      })
                    }
                  >
                    <img
                      src={pdf}
                      alt="pdf"
                      width="19"
                      style={{ margin: "2px" }}
                    />
                  </button>
                </Group>
              </div>
            </div>
            {/* Table data view */}
            <Table
              horizontalSpacing="md"
              verticalSpacing="xs"
              className={classes.striped}
            >
              {/* Table header defines */}
              <thead>
                <tr>
                  <Th>Sl.No</Th>
                  <Th>Date</Th>
                  <Th>Invoice</Th>
                  <Th>Customer</Th>
                  <Th>Discount</Th>
                  <Th>Subtotal</Th>
                  <Th>CGST</Th>
                  <Th>SGST</Th>
                  <Th>Total</Th>
                  <Th>Paid</Th>
                  <Th>Balance</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              {/* Table body defines from rows function */}
              <tbody key={refreshTable}>
                {rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td>
                      <Text weight={500} align="center">
                        Nothing found
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            {/* <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            /> */}
          </div>
          <Text mt={5} align="right" size="sm" color="green">
            Last updated on:&nbsp;
            {variables.data.length > 0
              ? new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")
              : null}
          </Text>
        </Card>
      </Skeleton>
      <PaymentInvoice
        value={goldValue}
        title={goldTitle}
        list={goldData}
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
      />
    </div>
  );
}
export default WeeklySales;
