import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { Card, Grid, Center, Text } from "@mantine/core/cjs";
import {
  AdjustmentsAlt,
  ArrowAutofitWidth,
  BuildingBank,
  CircleCheck,
  DatabaseImport,
  Discount2,
  FileInvoice,
  Location,
  Pencil,
  Percentage,
  Printer,
  Refresh,
  Settings,
  TableExport,
  TriangleSquareCircle,
  UserExclamation,
  UserPlus,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon
import {
  Avatar,
  Container,
  createStyles,
  Box,
  Modal,
  Progress,
} from "@mantine/core"; //For import mantine required functions and theme //for import mantine required functions and theme
import useStyles from "../../components/Style";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import { handleOneUser, handleUpdate } from "../../helpers/apis"; // For connect to the electron backend from this helper apis
import packageJson from "../../../package.json";
import axios from "axios";

function Setting() {
  let navigate = useNavigate();
  const [data, setData] = useState("");
  const links = [
    { label: "Profile Setting", icon: UserExclamation, link: "/profile" },
    { label: "Account Settings", icon: AdjustmentsAlt, link: "/account" },
    { label: "Tax", icon: Percentage, link: "/tax" },
    { label: "HSN", icon: TriangleSquareCircle, link: "/hsn" },
    { label: "Sale Type", icon: TableExport, link: "/sale_type" },
    // { label: "Activation", icon: CircleCheck, link: "/activation" },
    // { label: "Update", icon: Refresh, link: "/update" },
    {
      label: "Invoice template",
      icon: FileInvoice,
      link: "/invoice",
    },
    // {
    //   label: "Data Base restore",
    //   icon: DatabaseImport,
    //   link: "/database",
    // },
    {
      label: "Banking",
      icon: BuildingBank,
      link: "/banking",
    },
    {
      label: "Address",
      icon: Location,
      link: "/address",
    },
  ];

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const response = await handleOneUser();
      if (response.status == 200) {
        console.log(response);
        setData(response.data.data);
      }

      // setTimeout(async () => {
      //   console.log("cheking");
      //   const response = await handleUpdate();
      // }, 6000);
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const handlePage = (e) => {
    navigate(e);
  };
  const { classes } = useStyles();
  const [statusText, setStatusText] = useState("");
  const [opened, setOpened] = useState(false);
  const [opened2, setOpened2] = useState(false);
  const [counter, setCounter] = useState(0);
  const [complete, setComplete] = useState(false);
  const updateCheck = async (e) => {
    if ("/activation" == e) {
      setOpened2(true);
    } else {
      setCounter(0);
      // setOpened(true);

      const progressCount = setInterval(() => {
        setCounter((counter) => counter + 10);
      }, 500);

      setTimeout(function () {
        clearInterval(progressCount);
      }, 5500);
      console.log("checking-update");
      const response2 = await axios.get(
        "https://api.github.com/repos/zevcoredev/Zevcore-APOS/releases/latest",
        {
          headers: {
            Authorization: "Bearer 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",
          },
        }
      );
      var checking = true;
      if (checking == true) {
        console.log(response2.data.name);
        if (response2.status == 200) {
          const response = await handleUpdate();
          console.log(response);
          if (response.status == 200) {
            setStatusText(response2.data.name);
          }
        }
        checking = false;
      }
    }
  };
  return (
    <div>
      <BreadCrumb Text="Setting" />
      <div
        justify="center"
        align="center"
        style={{ marginBottom: 15, marginTop: 40 }}
      ></div>
      <Container style={{ width: "55%", margin: "auto" }}>
        <Grid>
          {links.map((item, index) => (
            <Grid.Col md={3} lg={3}>
              <div
                className={classes.SettingBox2}
                onClick={() => {
                  "/update" == item.link || "/activation" == item.link
                    ? updateCheck(item.link)
                    : navigate(item.link);
                }}
              >
                <Pencil className={classes.editIcon2} size={15} />
                <Box>
                  <item.icon size={50} strokeWidth="0.9" />
                  <Text sx={{ fontSize: "12px" }}>{item.label}</Text>
                </Box>
              </div>
            </Grid.Col>
          ))}
        </Grid>
      </Container>
      <div style={{ position: "absolute", bottom: 0, right: 0 }}>
        <Text size="xs" color="dimmed" mt={2}>
          © {new Date().getFullYear()}. Zevcore Private Limited. All Rights
          Reserved. Version - {packageJson.version.toString()} &nbsp;
        </Text>
      </div>
      <Modal
        centered
        opened={opened}
        title={"Software Update v-" + packageJson.version.toString()}
        onClose={() => setOpened(false)}
      >
        {counter >= 100 ? (
          <Text>
            {statusText.toString() == packageJson.version.toString() ? (
              <>Your software is upto date </>
            ) : (
              <>
                New update is available and started downloading. <br /> After
                download close the application and re-open after install
              </>
            )}
          </Text>
        ) : (
          <>
            <Progress value={counter} animate />
            <Text>Checking for latest update</Text>
          </>
        )}
      </Modal>
      <Modal
        withCloseButton={false}
        centered
        opened={opened2}
        onClose={() => setOpened2(false)}
      >
        <Text>
          Activated On -{" "}
          {data != "" && data != null
            ? new Date(data.createdAt).toLocaleDateString("en-UK")
            : null}
        </Text>
        {data != "" && data != null ? (
          <>
            {new Date(data.createdAt).getMonth() == "01" ? (
              <>
                {new Date(data.createdAt).getDate() == "29" ? (
                  <Text>
                    Next AMC -{" "}
                    {new Date(
                      new Date("March 01, 2022 00:20:18").setFullYear(
                        new Date().getFullYear() + 1
                      )
                    ).toLocaleDateString("en-UK")}
                  </Text>
                ) : (
                  <Text>
                    Next AMC -{" "}
                    {new Date(
                      new Date(data.createdAt).setFullYear(
                        new Date().getFullYear() + 1
                      )
                    ).toLocaleDateString("en-UK")}
                  </Text>
                )}
              </>
            ) : (
              <Text>
                Next AMC -{" "}
                {new Date(
                  new Date(data.createdAt).setFullYear(
                    new Date().getFullYear() + 1
                  )
                ).toLocaleDateString("en-UK")}
              </Text>
            )}
            <Text>Email - {data.email}</Text>
            <Text>Phone Number - {data.phone_number} </Text>
          </>
        ) : null}
      </Modal>
    </div>
  );
}

export default Setting;
