import axios from "axios";
import { URL_CONSTANTS } from "../constants/constants";

const config = {
  headers: {
    "x-access-token": localStorage.getItem("token"),
  },
};

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

// AUTH API

// For handle login
export const handleLogin = async (request) => {
  try {
    // URL for login
    const response = await axios.post(URL_CONSTANTS.LOGIN_URL, request);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle registration
export const handleRegister = async (request) => {
  try {
    // URL for registration
    const response = await axios.post(URL_CONSTANTS.REGISTER_URL, request);
    // return response;
  } catch (error) {
    return error.response;
  }
};

// AUTH API

// USER API

// For minimize Window
export const handleOneUser = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.USER_ONE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For minimize Window
export const handleEditUser = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.USER_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// USER API

// REPORT API

// For handle product history
export const handleGetProductHistory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCTHIST_REPORT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle product history data date wise
export const handleGetProductHistoryDate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCTHISTDATE_REPORT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle customer history data
export const handleGetCustomerHistory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.CUSTOMERHIST_REPORT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle customer history data
export const handleGetCustomerHistory2 = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.CUSTOMERHIST2_REPORT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle customer history data
export const handleGetCustomerHistoryDate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.CUSTOMERHISTDATE_REPORT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleGetKPI = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.KPI_REPORT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleDashboard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DASHBOARD_REPORT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleGetCustomerReport = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CUSTOMER_REPORT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleGetStockDepletion = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.STOCKDEP_REPORT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// REPORT API

// ACCOUNT API

// For handle get account data list
export const handleGetAccount = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.ACCOUNT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get Account data list
export const handleAddAccount = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.ACCOUNT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// ACCOUNT API

// TAX API

// For handle get tax data list
export const handleGetTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.TAX_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add tax data list
export const handleAddTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.TAX_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleEditTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.TAX_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete tax data list
export const handleDeleteTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.TAX_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleBulkTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.TAX_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// TAX API

// HSN CODE API

// For handle get tax data list
export const handleGetHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.HSN_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add tax data list
export const handleAddHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.HSN_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleEditHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.HSN_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete tax data list
export const handleDeleteHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.HSN_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleBulkHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.HSN_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// HSN CODE API

// STOCK TYPE API

// For handle get tax data list
export const handleGetStockType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.STOCKTYPE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// STOCK TYPE API

// SALE TYPE API

// For handle get sale_type data list
export const handleGetSaleType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.SALETYPE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add sale_type data list
export const handleAddSaleType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SALETYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit sale_type data list
export const handleEditSaleType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.SALETYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit sale_type data list
export const handleDeleteSaleType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.SALETYPE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit sale_type data list
export const handleBulkSaleType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SALETYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// SALE TYPE API

// BANK API

// For handle get bank
export const handleGetBank = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.BANK_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add bank
export const handleAddBank = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.BANK_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit bank
export const handleEditBank = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.BANK_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete bank
export const handleDeleteBank = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.BANK_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle Bulk bank
export const handleBulkBank = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.BANK_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// BANK API

// ADDRESS API

// For handle get address
export const handleGetOneAddress = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.ADDRESS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get address
export const handleGetAddress = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.ADDRESS_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add address
export const handleAddAddress = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.ADDRESS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit address
export const handleEditAddress = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.ADDRESS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete address
export const handleDeleteAddress = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.ADDRESS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get address
export const handleBulkAddress = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.ADDRESS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// ADDRESS API

// CUSTOMER API

// For handle get customer data list
export const handleGetCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CUSTOMER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add customer data list
export const handleAddCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CUSTOMER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit customer data list
export const handleEditCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CUSTOMER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit customer data list
export const handleDeleteCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CUSTOMER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Customer data list
export const handleBulkCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CUSTOMER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CUSTOMER API

// CATEGORY API

// For handle get group data list                                                                                                   // GROUP
export const handleGetGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CATEGORY_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add group data list
export const handleAddGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit group data list
export const handleEditGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit group data list
export const handleDeleteGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CATEGORY_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit group data list
export const handleBulkGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CATEGORY API

// PRODUCT API

// For handle get product data list
export const handleGetProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PRODUCT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add product data list
export const handleAddProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add product data list
export const handleSearchProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_URL + "/search",
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit product data list
export const handleEditProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit product data list
export const handleDeleteProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PRODUCT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit product data list
export const handleBulkProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT API

// VENDOR API

// For handle get vendor data list
export const handleGetVendor = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.VENDOR_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add vendor data list
export const handleAddVendor = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.VENDOR_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit vendor data list
export const handleEditVendor = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.VENDOR_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit vendor data list
export const handleDeleteVendor = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.VENDOR_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Vendor data list
export const handleBulkVendor = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.VENDOR_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// VENDOR API

// PO API

// For handle edit Customer data list
export const handleGetPO = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PO_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Customer data list
export const handleAddPO = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.PO_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Customer data list
export const handleEditPO = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.PO_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Customer data list
export const handleDeletePO = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PO_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Customer data list
export const handleDeletePOList = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PO_LIST_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PO API

// STOCK API

// For handle stock add
export const handleGetStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.STOCK_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle stock add
export const handleGetProductStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.STOCK_URL + "/product",
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle stock add
export const handleGetAllProductStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.STOCK_URL + "/product" + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add product data list
export const handleSearchStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.STOCK_URL + "/search",
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle bulk stock add
export const handleAddStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.STOCK_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle bulk stock add
export const handleDeleteStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.STOCK_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// STOCK API

// PACKET STOCK API

// For handle unit stock add
export const handleAddUnitStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PACKETSTOCKCREATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle packet stock fetch
export const handleGetPacketStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PACKETSTOCK_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle stock add
export const handleGetProductPacketStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PACKETSTOCK_URL + "/product",
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle packet stock add
export const handleAddPacketStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PACKETSTOCK_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle packet stock delete
export const handleDeletePacketStock = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.STOCK_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PACKET STOCK API

// CASH LEDGER API

// For handle get cash
export const handleGetCash = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CASHLEDGER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete cash
export const handleSearch = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CASHLEDGER_URL + "/search",
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add cash
export const handleAddCash = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CASHLEDGER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit cash
export const handleEditCash = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CASHLEDGER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete cash
export const handleDeleteCash = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CASHLEDGER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CASH LEDGER API

// BANK LEDGER API

// For handle get cash
export const handleGetBankLedger = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.BANKLEDGER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add cash
export const handleAddBankLedger = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.BANKLEDGER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit cash
export const handleEditBankLedger = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.BANKLEDGER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete cash
export const handleDeleteBankLedger = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.BANKLEDGER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete cash
export const handleSearchBank = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.BANKLEDGER_URL + "/search",
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// BANK LEDGER API

// REMINDER API

// For handle delete cash
export const handleAddReminder = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REMINDER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete cash
export const handleGetReminder = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.REMINDER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete cash
export const handleTodayReminder = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.REMINDER_URL + "/today",
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete cash
export const handleDeleteReminder = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.REMINDER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// REMINDER API

// INVOICE API

// For handle invoice add
export const handleGetOneInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.INVOICE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleGetInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.INVOICE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleAddInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.INVOICE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleAddInvoice2 = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.INVOICE2_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For delete the invoice data
export const handleDeleteInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.INVOICE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// INVOICE API

// SAVE INVOICE API

// For handle invoice add
export const handleGetSaveInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.SAVEINVOICE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleGetOneSaveInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SAVEINVOICEONE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleSaveInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SAVEINVOICE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleDeleteSaveInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.SAVEINVOICE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// SAVE INVOICE API

// ESTIMATE API

// For handle invoice add
export const handleGetEstimate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.ESTIMATE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleAddEstimate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.ESTIMATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleGetOneEstimate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.ESTIMATE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete cash
export const handleDeleteEstimate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.ESTIMATE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// ESTIMATE API

// JOURNAL API

// For handle add Journal list
export const handleAddJournal = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.JOURNAL_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// JOURNAL API

// PAYMENT API

// For handle add Journal list
export const handelGetPayments = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PAYMENT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add payments list
export const handleAddPayment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PAYMENT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PAYMENT API

// PASSBOOK API

// For handle invoice add
export const handleGetPassbook = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PASSBOOK_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleAddPassbook = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PASSBOOK_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PASSBOOK API

// REPORT API

// Two date report
export const handleGetTwoDate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REPORT_DATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleGetHSNTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS + "hsn_tax", config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// REPORT API

// For handle add product data list
export const handleUserCount = async (request) => {
  try {
    // // const response = await routes.auth.userCount(request);
    // // return response;
  } catch (error) {
    return error.response;
  }
};

// For close Window
export const handlePathURL = async (request) => {
  try {
    // // const response = await routes.extra.pathURL();
    // // return response;
  } catch (error) {
    return error.response;
  }
};

// Get db folder path
export const handlePath = async (request) => {
  try {
    // // const response = await routes.extra.getPath();
    // // return response;
  } catch (error) {
    return error.response;
  }
};

// For close Window
export const handleClose = async (request) => {
  try {
    // // const response = await routes.extra.closeWindow();
    // // return response;
  } catch (error) {
    return error.response;
  }
};

// For minimize Window
export const handleMinimize = async (request) => {
  try {
    // const response = await routes.extra.minimize();
    // return response;
  } catch (error) {
    return error.response;
  }
};

// For minimize Window
export const handleHide = async (request) => {
  try {
    // // const response = await routes.extra.hide();
    // // return response;
  } catch (error) {
    return error.response;
  }
};

// For minimize Window
export const handleMaximize = async (request) => {
  try {
    // // const response = await routes.extra.maximize();
    // // return response;
  } catch (error) {
    return error.response;
  }
};

// For minimize Window
export const handleUnmaximize = async (request) => {
  try {
    // // const response = await routes.extra.unmaximize();
    // // return response;
  } catch (error) {
    return error.response;
  }
};

// For minimize Window
export const handleReload = async (request) => {
  try {
    // // const response = await routes.extra.reload();
    // // return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add product data list
export const handleMailOtp = async (request) => {
  try {
    // // const response = await routes.extra.otpMail(request);
    // // return response;
  } catch (error) {
    return error.response;
  }
};

export const handleBackupDataBase = async (request) => {
  try {
    // // const response = await routes.extra.backupDataBase(request);
    // // return response;
  } catch (error) {
    return error.response;
  }
};

export const handleUpdate = async (request) => {
  try {
    // // const response = await routes.extra.update(request);
    // // return response;
  } catch (error) {
    return error.response;
  }
};
