import React, { useState, useEffect, useRef, useCallback } from "react";
import BreadCrumb from "../../components/BreadCrumb"; // For breadcrumbs data import
import useStyles from "../../components/Style"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  Menu,
  ActionIcon,
  Select,
  NumberInput,
  Tabs,
  Avatar,
  Box,
  Progress,
  Grid,
  Modal,
  Paper,
} from "@mantine/core"; //For import mantine required functions and theme // Mantine all required compoennts imports list
import {
  Dots,
  Pencil,
  Search,
  Trash,
  CloudUpload,
  DotsCircleHorizontal,
  X,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import { CSVLink } from "react-csv"; // For export the data to excel format
import {
  handleGetGroup,
  handleAddProduct,
  handleDeleteProduct,
  handleEditProduct,
  handleGetProduct,
  handleBackupUpload,
  handleBulkProduct,
  handleGetTax,
  handleGetProductHistory,
  handlePathURL,
  handleSearchProduct,
  handleGetHSN,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Import for axios requests list for this pages
import notificationHelper from "../../helpers/notification"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import {
  dataSlice,
  getActiveColor,
  imageModal,
  selectFilter,
  onDownload,
  pageModal,
} from "../../helpers/common"; // Common fuctions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../assets/images/excel.png"; // Image for excel export
import pdf from "../../assets/images/pdf.png"; // Image for pdf exports

// for image crop
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import { IconCloudUpload, IconX, IconDownload } from "@tabler/icons";
import { useInterval } from "@mantine/hooks";
import readXlsxFile from "read-excel-file";
import GoldProduct from "../../helpers/GoldProduct";
import { useDebounceEffect } from "../common/userDebounceEffect.ts";
import { DatePicker } from "@mantine/dates";
import { URL_CONSTANTS } from "../../constants/constants";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

// For unzip the file data

function Product() {
  // Mantine custome style use
  const { classes, theme } = useStyles();
  const modals = useModals();
  const [imageURL, setImageURL] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [imageArrayEdit, setImageArrayEdit] = useState([]);
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [sortBy, setSortBy] = useState(null); // Seting the sortby table type
  const [reverseSortDirection, setReverseSortDirection] = useState(false); // For set the reverse sort direction
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  const [sortedData2, setSortedData2] = useState([]);

  // Product golden
  const [productModal, setProductModal] = useState(false);
  const [goldTitle, setGoldTitle] = useState("");
  const [goldValue, setGoldValue] = useState("");
  const [goldData, setGoldData] = useState("");

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 1 / 1,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e[0]) {
      console.log(e[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e[0]);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    groupList: [],
    subgroupList: [],
    taxList: [],
    hsnList: [],
    data: [],
    addDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  // For form validation
  const formSearch = useForm({
    initialValues: {
      product_type: "",
    },
  });

  // For form validation
  const form = useForm({
    initialValues: {
      name: "",
      category_id: "1",
      sku: "",
      hsn: "",
      bulk_price: 0,
      unit_price: 0,
      tax: "",
      stock_depletion: 0,
    },
    validate: {
      name: (value) => (value.length < 1 ? "Product name is required" : null),
      // sku: (value) => (value === "" ? "Product sku is required" : null),
    },
  });

  //   For edit form data validation
  const formEdit = useForm({
    initialValues: {
      name: "",
      category_id: "",
      sku: "",
      hsn: "",
      bulk_price: 0,
      unit_price: 0,
      stock_depletion: 0,
      tax: "",
      image: "",
    },
    validate: {
      name: (value) => (value.length < 1 ? "Product name is required" : null),
      sku: (value) => (value === "" ? "Product sku is required" : null),
    },
  });

  //   For intial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the product data list
        const response = await handleGetProduct();
        // On Response setting the data to variable
        if (response.status === 200) {
          setSortedData2(response.data.data);
          console.log(response.data);
          setVariables((variables) => {
            return {
              ...variables,
              data: response.data.data,
              skeletonLoading: false,
            };
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }
        const response2 = await handleGetGroup();

        if (response2.status === 200) {
          const listGroup = await selectFilter({
            data: response2.data.data,
          });

          setVariables((variables) => {
            return {
              ...variables,
              groupList: listGroup,
              skeletonLoading: false,
            };
          });
        }

        const response3 = await handleGetHSN();

        // On Respose setting the data to variable
        if (response3.status === 200) {
          const listGroup = await selectFilter({
            data: response3.data.data,
          });
          setVariables((variables) => {
            return {
              ...variables,
              hsnList: listGroup,
              skeletonLoading: false,
            };
          });
        }

        const response5 = await handleGetTax();
        if (response5.status === 200) {
          if (response5.data.data.length == 0) {
            pageModal({
              data: "./tax",
              title: "Add tax percentage before proceed to product",
            });
          }
          const listTax = await response5.data.data.map((data) => ({
            value: data.label.toString(),
            label: data.label.toString(),
          }));

          setVariables((variables) => {
            return {
              ...variables,
              taxList: listTax,
              skeletonLoading: false,
            };
          });
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data arrange by using function and loop throw each data arrange to table body
  const rows = sortedData.map((row, index) => (
    <tr key={row.label}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.PRODUCT_IMAGE + row.image,
                  title: row.label,
                });
              }}
              size={20}
              src={URL_CONSTANTS.PRODUCT_IMAGE + row.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.PRODUCT_IMAGE + row.image,
                  title: row.label,
                });
              }}
              size={20}
              src={URL_CONSTANTS.PRODUCT_IMAGE + row.image}
            />
          )}

          <Group>
            {row.label}
            <ActionIcon
              onClick={async () => {
                const data = await handleGetProductHistory(row.value);
                const list = data.data.data;
                setProductModal(true);
                setGoldData(list);
                setGoldTitle(row.label);
                setGoldValue(row.value);
              }}
              ml={-10}
              color="zevcore"
              variant="transparent"
            >
              <DotsCircleHorizontal size={20} />
            </ActionIcon>
          </Group>
        </Group>
      </td>
      <td>
        {row.category != "" && typeof row.category != "undefined"
          ? row.category.label
          : "-"}
      </td>
      <td>{row.tax} %</td>
      <td>{row.sku}</td>
      <td>{row.hsn.label}</td>
      <td>{row.bulk_price}</td>
      <td>{row.unit_price}</td>
      <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
      {/* For action drop down edit and delete the data */}
      <td justifycontent="right" align="right">
        <Menu shadow="sm" size="xs">
          <Menu.Target>
            <ActionIcon
              color="zevcore"
              type="button"
              style={{ marginLeft: 10 }}
              size="xs"
            >
              <Dots />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => handleEdit(row.value)}
              icon={<Pencil size={14} />}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              onClick={() => openConfirmModal(row.value)}
              icon={<Trash size={14} />}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </td>
    </tr>
  ));

  //For delete confirm modal show Delete
  const openConfirmModal = (e) => {
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Do you want to delete this product value",
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteProduct(e);

    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        submitLoading: false,
        data: filter,
      });
      const datas = dataSlice({
        data: filter,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // set editable data to form Edit form
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    formEdit.setFieldValue("value", datas.value);
    formEdit.setFieldValue("name", datas.label);
    formEdit.setFieldValue(
      "category_id",
      datas.category_id != null ? datas.category_id.toString() : ""
    );
    formEdit.setFieldValue("sku", datas.sku);
    formEdit.setFieldValue("stock_depletion", datas.stock_depletion);
    formEdit.setFieldValue(
      "hsn",
      datas.hsn_id != null ? datas.hsn_id.toString() : ""
    );
    // formEdit.setFieldValue("hsn", datas.hsn_id);
    formEdit.setFieldValue("bulk_price", datas.bulk_price);
    formEdit.setFieldValue("unit_price", datas.unit_price);
    formEdit.setFieldValue("tax", datas.tax.toString());
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Edit product data compoennt
  const EditProduct = async (e) => {
    var req = {
      image: image,
    };
    e = { ...e, ...req };
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditProduct(e);
    console.log(response.data);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Add new product data
  const AddProduct = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
    };
    e = { ...e, ...req };

    const response = await handleAddProduct(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product added successfully",
      });
      form.reset();
      setUpImg("");
      setImage("");
      setCrop({
        unit: "%",
        width: 10,
        aspect: 24 / 24,
      });
      setAspect(1 / 1);
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // For bulk upload
  const [bulkData, setBulkData] = useState([]);

  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const bulkFile789 = async (event) => {
    let xlsxfile = event.target.files ? event.target.files[0] : null;

    var data = [];
    readXlsxFile(xlsxfile).then((rows) => {
      data = rows;
      setBulkData(data);
    });

    ref.current.value = "";
  };
  const interval = useInterval(
    () =>
      setProgress((current) => {
        if (current < 100) {
          return current + 1;
        }
        return 0;
      }),
    20
  );
  // Upload bulk json data product
  const BulkDataUpload = async () => {
    setVariables({ ...variables, submitLoading: true });

    const response = await handleBulkProduct(bulkData);
    // Check for response for actions
    if (response.status === 200) {
      interval.stop();
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Bulk product added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      interval.stop();
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Add new Product data
  const handleAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleSearchProduct(e);
    // Check for response for actions
    // On Response setting the data to variable
    if (response.status === 200) {
      setVariables((variables) => {
        return {
          ...variables,
          data: response.data.data,
          skeletonLoading: false,
        };
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setSortedData(datas);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const [selectGroup, setSelectGroup] = useState("");
  const [selectHSN, setSelectHSN] = useState("");

  const AddInvoice = () => {
    var datas = sortedData2;
    var filter = "";
    var date2 = "";
    if (selectGroup != "") {
      datas = datas.filter(function (item) {
        if (Number(selectGroup) != Number(item.category_id)) {
          return false;
        }
        return true;
      });
    }
    if (selectHSN != "") {
      datas = datas.filter(function (item) {
        if (Number(selectHSN) != Number(item.hsn_id)) {
          return false;
        }
        return true;
      });
    }
    setVariables((variables) => {
      return {
        ...variables,
        data: datas,
        skeletonLoading: false,
      };
    });
    const datas2 = dataSlice({
      data: datas,
      page: 1,
      total: 10,
    });
    setSortedData(datas2);
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Products" />
      </Skeleton>

      <Paper mb={5} p={15} className="border">
        <Group>
          <Select
            label="Select Category"
            variant="filled"
            fullWidth
            dropdownPosition="bottom"
            searchable
            clearable
            placeholder="Select Category"
            data={variables.groupList}
            value={selectGroup}
            onChange={(e) => {
              setSelectGroup(e);
            }}
            mr={2}
          />
          <Select
            label="Select HSN"
            variant="filled"
            value={selectHSN}
            onChange={(e) => {
              setSelectHSN(e);
            }}
            placeholder="Select HSN"
            data={variables.hsnList}
          />

          <Button
            loading={variables.submitLoading}
            onClick={() => AddInvoice()}
            mt={22}
            leftIcon={<Search size={14} />}
          >
            Search
          </Button>
          <Button
            loading={variables.submitLoading}
            onClick={() => {
              setSelectHSN("");
              setSelectGroup("");
              setVariables((variables) => {
                return {
                  ...variables,
                  data: sortedData2,
                  skeletonLoading: false,
                };
              });
              const datas2 = dataSlice({
                data: sortedData2,
                page: 1,
                total: 10,
              });
              setSortedData(datas2);
            }}
            mt={22}
          >
            Clear
          </Button>
        </Group>
      </Paper>

      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search by any field"
                mb="md"
                size="xs"
                value={search}
                icon={<Search size={14} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[{ label: " Product Name", key: "label" }]}
                    filename="product.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  <button
                    className={classes.pdfExcel}
                    onClick={() =>
                      print({
                        data: variables.data,
                        list: [
                          "label",
                          "sku",
                          "hsn",
                          "bulk_price",
                          "unit_price",
                          "category.label",
                        ],
                        header: [
                          "Product Name",
                          "SKU",
                          "HSN",
                          "Bulk Price",
                          "Unit Price",
                          "Category",
                        ],
                        type: "portrait", // Add change if u want landscape or portrait
                        name: "product",
                      })
                    }
                  >
                    <img
                      src={pdf}
                      alt="pdf"
                      width="19"
                      style={{ margin: "2px" }}
                    />
                  </button>
                  {/* Drawer open for adding new product data */}
                  <Button
                    variant="outline"
                    color="zevcore"
                    size="xs"
                    onClick={() => {
                      setImageArray([]);
                      setVariables({ ...variables, addDrawer: true });
                    }}
                  >
                    + Add Product
                  </Button>
                </Group>
              </div>
            </div>

            {/* Table data view */}
            <Table
              horizontalSpacing="md"
              verticalSpacing="xs"
              className={classes.striped}
            >
              {/* Table header defines */}
              <thead>
                <tr>
                  <Th>Sl.No</Th>
                  <Th
                    sorted={sortBy === "label"}
                    reversed={reverseSortDirection}
                    onSort={async () => {
                      const reversed =
                        "label" === sortBy ? !reverseSortDirection : false;
                      setReverseSortDirection(reversed);
                      setSortBy("label");
                      const datas = await setSorting({
                        data: variables.data,
                        sortby: "label",
                        reversed: reversed,
                        search: search,
                        activePage: activePage,
                        total: total,
                      });
                      setSortedData(datas);
                      setRefreshTable(new Date());
                    }}
                  >
                    Product
                  </Th>
                  <Th>Category</Th>
                  <Th>GST %</Th>
                  <Th
                    sorted={sortBy === "sku"}
                    reversed={reverseSortDirection}
                    onSort={async () => {
                      const reversed =
                        "sku" === sortBy ? !reverseSortDirection : false;
                      setReverseSortDirection(reversed);
                      setSortBy("sku");
                      const datas = await setSorting({
                        data: variables.data,
                        sortby: "sku",
                        reversed: reversed,
                        search: search,
                        activePage: activePage,
                        total: total,
                      });
                      setSortedData(datas);
                      setRefreshTable(new Date());
                    }}
                  >
                    SKU
                  </Th>
                  <Th
                    sorted={sortBy === "hsn"}
                    reversed={reverseSortDirection}
                    onSort={async () => {
                      const reversed =
                        "hsn" === sortBy ? !reverseSortDirection : false;
                      setReverseSortDirection(reversed);
                      setSortBy("hsn");
                      const datas = await setSorting({
                        data: variables.data,
                        sortby: "hsn",
                        reversed: reversed,
                        search: search,
                        activePage: activePage,
                        total: total,
                      });
                      setSortedData(datas);
                      setRefreshTable(new Date());
                    }}
                  >
                    HSN
                  </Th>
                  <Th>Bulk Price</Th>
                  <Th>Unit Price</Th>
                  <Th>Created At</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              {/* Table body defines from rows function */}
              <tbody key={refreshTable}>
                {rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td>
                      <Text weight={500} align="center">
                        Nothing found
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>

          {variables.data.length > 0 ? (
            <>
              <Text mt={5} align="right" size="sm" color="green">
                Last updated on:&nbsp;
                {new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")}
              </Text>
            </>
          ) : null}
        </Card>
      </Skeleton>

      {/* Product Add drawer */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => {
          setVariables({ ...variables, addDrawer: false });
          setUpImg("");
          setImage("");
          setCrop({
            unit: "%",
            width: 10,
            aspect: 24 / 24,
          });
          setAspect(1 / 1);
        }}
        withCloseButton={false}
        overlayOpacity={0.55}
        padding={0}
        overlayBlur={3}
        size="100%"
      >
        <div
          style={{
            display: "flex",
            padding: 0,
            margin: 0,
            justifyContent: "space-between",
            background: "#043c64",
            borderTopRightRadius: 3,
            borderTopLeftRadius: 3,
          }}
        >
          <Text color="#ffffff" size={14} m={5} mt={8} ml={15}>
            Add Product
          </Text>
          <ActionIcon
            onClick={() => setVariables({ ...variables, addDrawer: false })}
            m={5}
            sx={{
              "&[data-disabled]": { opacity: 1 },
              "&[data-loading]": { backgroundColor: "#ffffff" },
            }}
          >
            <X size={18} />
          </ActionIcon>
        </div>
        {/* Drawer content */}
        <div className="zc-p-1">
          <ScrollArea
            // style={{
            //   height: "90vh",
            // }}
            type="scroll"
            offsetScrollbars
            scrollbarSize={5}
          >
            {/* Product adding form name */}
            <div className="zc-pr-3 zc-pl-3">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <Button
                  variant="outline"
                  color="zevcore"
                  size="xs"
                  onClick={() =>
                    setVariables({
                      ...variables,
                      bulkDrawer: true,
                      addDrawer: false,
                    })
                  }
                >
                  Bulk Import
                </Button>
              </div>

              {/* For adding new product form data */}
              <form onSubmit={form.onSubmit((values) => AddProduct(values))}>
                <Grid>
                  <Grid.Col md={4} lg={4} mt={1}>
                    {/* For image croper */}
                    {/* For croper */}
                    {upImg !== "" && upImg !== null ? (
                      <>
                        <ReactCrop
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => setCompletedCrop(c)}
                          aspect={aspect}
                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={upImg}
                            style={{
                              transform: `scale(${scale}) rotate(${rotate}deg)`,
                            }}
                            onLoad={onImageLoad}
                          />
                        </ReactCrop>
                        <div>
                          {previewCanvasRef != null ? (
                            <canvas
                              ref={previewCanvasRef}
                              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                              style={{
                                width: Math.round(completedCrop?.width ?? 0),
                                height: Math.round(completedCrop?.height ?? 0),
                                marginBottom: 50,
                                display: "none",
                              }}
                            />
                          ) : null}

                          <Group position="right" mt="md" mb={20}>
                            <Button
                              type="submit"
                              color="zevcore"
                              onClick={() => {
                                setUpImg("");
                                setImage("");
                              }}
                            >
                              Clear Image
                            </Button>
                          </Group>
                        </div>
                      </>
                    ) : (
                      // For selecting cropping image dropdown
                      <div
                        style={{
                          marginTop: 15,
                          position: "relative",
                          marginBottom: 30,
                        }}
                      >
                        <Dropzone
                          openRef={openRef}
                          onDrop={changeHandler}
                          style={{
                            borderWidth: 1,
                            paddingBottom: 50,
                          }}
                          radius="md"
                          accept={[
                            "image/png",
                            "image/jpeg",
                            "image/sgv+xml",
                            "image/gif",
                          ]}
                          maxSize={30 * 1024 ** 2}
                        >
                          <div style={{ pointerEvents: "none" }}>
                            <Group position="center">
                              <Dropzone.Accept>
                                <IconDownload
                                  size={50}
                                  color={theme.colors[theme.primaryColor][6]}
                                  stroke={1.5}
                                />
                              </Dropzone.Accept>
                              <Dropzone.Reject>
                                <IconX
                                  size={50}
                                  color={theme.colors.red[6]}
                                  stroke={1.5}
                                />
                              </Dropzone.Reject>
                              <Dropzone.Idle>
                                <IconCloudUpload
                                  size={50}
                                  color={
                                    theme.colorScheme === "dark"
                                      ? theme.colors.dark[0]
                                      : theme.black
                                  }
                                  stroke={1.5}
                                />
                              </Dropzone.Idle>
                            </Group>
                            <Text align="center" weight={700} size="lg" mt="xl">
                              <Dropzone.Accept>Drop files here</Dropzone.Accept>
                              <Dropzone.Reject>
                                Pdf file less than 30mb
                              </Dropzone.Reject>
                              <Dropzone.Idle>Upload image</Dropzone.Idle>
                            </Text>
                            <Text
                              align="center"
                              size="sm"
                              mt="xs"
                              color="dimmed"
                            >
                              Drag&apos;n&apos;drop files here to upload product
                              image. We can accept only <i>.png</i> &{" "}
                              <i>.jpg</i> files that are less than 10mb in size.
                              This product image will used for show in product
                              list
                            </Text>
                          </div>
                        </Dropzone>
                        <Button
                          style={{
                            position: "absolute",
                            width: 250,
                            left: "calc(50% - 125px)",
                            bottom: -20,
                          }}
                          size="md"
                          color="zevcore"
                          radius="xl"
                          onClick={() => openRef.current?.()}
                        >
                          Select files
                        </Button>
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col md={8} lg={8} mt={1}>
                    <Grid pt={10}>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <Select
                          variant="filled"
                          classNames={{ item: classes.selectItem }}
                          label="Select Category"
                          searchable
                          required
                          clearable
                          mb="md"
                          placeholder="Select Category"
                          {...form.getInputProps("category_id")}
                          data={variables.groupList}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <TextInput
                          required
                          variant="filled"
                          value={form.values.name}
                          label="Product"
                          placeholder="Product Name"
                          {...form.getInputProps("name")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <TextInput
                          variant="filled"
                          value={form.values.sku}
                          label="SKU"
                          placeholder="Product SKU"
                          {...form.getInputProps("sku")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        {/* <TextInput
                          variant="filled"
                          value={form.values.hsn}
                          label="HSN"
                          placeholder="Product HSN"
                          {...form.getInputProps("hsn")}
                        /> */}
                        <Select
                          label="Select HSN"
                          variant="filled"
                          value={selectHSN}
                          {...form.getInputProps("hsn")}
                          placeholder="Select HSN"
                          data={variables.hsnList}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <NumberInput
                          required
                          variant="filled"
                          value={form.values.price}
                          label="Bulk Price / KG"
                          placeholder="Product Bulk Price"
                          {...form.getInputProps("bulk_price")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <NumberInput
                          required
                          variant="filled"
                          value={form.values.price}
                          label="Unit Price / Unit"
                          placeholder="Product Unit Price"
                          {...form.getInputProps("unit_price")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <NumberInput
                          variant="filled"
                          value={form.values.stock_depletion}
                          label="Low Stock Notification Limit (KG)"
                          placeholder=" Low Stock Notification Limit"
                          {...form.getInputProps("stock_depletion")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <Select
                          required
                          variant="filled"
                          classNames={{ item: classes.selectItem }}
                          label="Select GST %"
                          searchable
                          clearable
                          mb="md"
                          placeholder="Select GST %"
                          {...form.getInputProps("tax")}
                          data={variables.taxList}
                        />
                      </Grid.Col>
                      <Button
                        mt="xl"
                        mb="xl"
                        type="submit"
                        fullWidth
                        color="zevcore"
                        loading={variables.submitLoading}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
          </ScrollArea>
        </div>
      </Modal>
      {/* Product add drawer end */}

      {/* Product Edit drawer */}
      <Modal
        opened={variables.openEdit}
        onClose={() => {
          setVariables({ ...variables, openEdit: false });
          setUpImg("");
          setImage("");
        }}
        withCloseButton={false}
        overlayOpacity={0.55}
        padding={0}
        overlayBlur={3}
        size="100%"
      >
        <div
          style={{
            display: "flex",
            padding: 0,
            margin: 0,
            justifyContent: "space-between",
            background: "#043c64",
            borderTopRightRadius: 3,
            borderTopLeftRadius: 3,
          }}
        >
          <Text color="#ffffff" size={14} m={5} mt={8} ml={15}>
            Edit Product
          </Text>
          <ActionIcon
            onClick={() => setVariables({ ...variables, openEdit: false })}
            m={5}
            sx={{
              "&[data-disabled]": { opacity: 1 },
              "&[data-loading]": { backgroundColor: "#ffffff" },
            }}
          >
            <X size={18} />
          </ActionIcon>
        </div>
        {/* Drawer content */}
        <div className="zc-p-3">
          <ScrollArea
            // style={{
            //   height: "90vh",
            // }}
            type="scroll"
            offsetScrollbars
            scrollbarSize={5}
          >
            {/* Product edit form name */}
            <div className="zc-pr-3 zc-pl-3">
              <form
                onSubmit={formEdit.onSubmit((values) => EditProduct(values))}
              >
                <Grid>
                  <Grid.Col md={4} lg={4} mt={1}>
                    {/* For image croper */}
                    {/* For croper */}
                    {upImg !== "" && upImg !== null ? (
                      <>
                        <ReactCrop
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => setCompletedCrop(c)}
                          aspect={aspect}
                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={upImg}
                            style={{
                              transform: `scale(${scale}) rotate(${rotate}deg)`,
                            }}
                            onLoad={onImageLoad}
                          />
                        </ReactCrop>
                        <div>
                          {previewCanvasRef != null ? (
                            <canvas
                              ref={previewCanvasRef}
                              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                              style={{
                                width: Math.round(completedCrop?.width ?? 0),
                                height: Math.round(completedCrop?.height ?? 0),
                                marginBottom: 50,
                                display: "none",
                              }}
                            />
                          ) : null}

                          <Group position="right" mt="md" mb={20}>
                            <Button
                              type="submit"
                              color="zevcore"
                              onClick={() => {
                                setUpImg("");
                                setImage("");
                              }}
                            >
                              Clear Image
                            </Button>
                          </Group>
                        </div>
                      </>
                    ) : (
                      // For selecting cropping image dropdown
                      <div
                        style={{
                          marginTop: 15,
                          position: "relative",
                          marginBottom: 30,
                        }}
                      >
                        <Dropzone
                          openRef={openRef}
                          onDrop={changeHandler}
                          style={{
                            borderWidth: 1,
                            paddingBottom: 50,
                          }}
                          radius="md"
                          accept={[
                            "image/png",
                            "image/jpeg",
                            "image/sgv+xml",
                            "image/gif",
                          ]}
                          maxSize={30 * 1024 ** 2}
                        >
                          <div style={{ pointerEvents: "none" }}>
                            <Group position="center">
                              <Dropzone.Accept>
                                <IconDownload
                                  size={50}
                                  color={theme.colors[theme.primaryColor][6]}
                                  stroke={1.5}
                                />
                              </Dropzone.Accept>
                              <Dropzone.Reject>
                                <IconX
                                  size={50}
                                  color={theme.colors.red[6]}
                                  stroke={1.5}
                                />
                              </Dropzone.Reject>
                              <Dropzone.Idle>
                                <IconCloudUpload
                                  size={50}
                                  color={
                                    theme.colorScheme === "dark"
                                      ? theme.colors.dark[0]
                                      : theme.black
                                  }
                                  stroke={1.5}
                                />
                              </Dropzone.Idle>
                            </Group>
                            <Text align="center" weight={700} size="lg" mt="xl">
                              <Dropzone.Accept>Drop files here</Dropzone.Accept>
                              <Dropzone.Reject>
                                Pdf file less than 30mb
                              </Dropzone.Reject>
                              <Dropzone.Idle>Upload image</Dropzone.Idle>
                            </Text>
                            <Text
                              align="center"
                              size="sm"
                              mt="xs"
                              color="dimmed"
                            >
                              Drag&apos;n&apos;drop files here to upload product
                              image. We can accept only <i>.png</i> &{" "}
                              <i>.jpg</i> files that are less than 10mb in size.
                              This product image will used for show in product
                              list
                            </Text>
                          </div>
                        </Dropzone>

                        <Button
                          style={{
                            position: "absolute",
                            width: 250,
                            left: "calc(50% - 125px)",
                            bottom: -20,
                          }}
                          size="md"
                          color="zevcore"
                          radius="xl"
                          onClick={() => openRef.current?.()}
                        >
                          Select files
                        </Button>
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col md={8} lg={8} mt={1}>
                    <Grid pt={10}>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <Select
                          variant="filled"
                          classNames={{ item: classes.selectItem }}
                          label="Select Category"
                          searchable
                          clearable
                          mb="md"
                          placeholder="Select Category"
                          {...formEdit.getInputProps("category_id")}
                          data={variables.groupList}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <TextInput
                          variant="filled"
                          value={formEdit.values.label}
                          label="Product Name"
                          placeholder="Product Name"
                          {...formEdit.getInputProps("name")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <TextInput
                          variant="filled"
                          value={formEdit.values.sku}
                          label="SKU"
                          placeholder="Product SKU"
                          {...formEdit.getInputProps("sku")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        {/* <TextInput
                          variant="filled"
                          value={formEdit.values.hsn}
                          label="HSN"
                          placeholder="Product HSN"
                          {...formEdit.getInputProps("hsn")}
                        /> */}
                        <Select
                          label="Select HSN"
                          variant="filled"
                          value={selectHSN}
                          {...formEdit.getInputProps("hsn")}
                          placeholder="Select HSN"
                          data={variables.hsnList}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <NumberInput
                          required
                          variant="filled"
                          value={formEdit.values.bulk_price}
                          label="Bulk Price / KG"
                          placeholder="Product Bulk Price"
                          {...formEdit.getInputProps("bulk_price")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <NumberInput
                          required
                          variant="filled"
                          value={formEdit.values.unit_price}
                          label="Unit Price / Unit"
                          placeholder="Product Unit Price"
                          {...formEdit.getInputProps("unit_price")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <NumberInput
                          variant="filled"
                          value={formEdit.values.stock_depletion}
                          label="Low Stock Notification Limit (KG)"
                          placeholder="Low Stock Notification Limit"
                          {...formEdit.getInputProps("stock_depletion")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4} mt={1}>
                        <Select
                          variant="filled"
                          classNames={{ item: classes.selectItem }}
                          label="Select GST %"
                          searchable
                          clearable
                          mb="md"
                          placeholder="Select GST %"
                          {...formEdit.getInputProps("tax")}
                          data={variables.taxList}
                        />
                      </Grid.Col>
                      <Button
                        mt="xl"
                        mb="xl"
                        type="submit"
                        fullWidth
                        color="zevcore"
                        loading={variables.submitLoading}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
          </ScrollArea>
        </div>
      </Modal>
      {/* Product edit drawer end */}

      {/* Group Bulk drawer */}
      <Modal
        opened={variables.bulkDrawer}
        withCloseButton={false}
        overlayOpacity={0.55}
        padding={0}
        overlayBlur={3}
        size="md"
      >
        <div
          style={{
            display: "flex",
            padding: 0,
            margin: 0,
            justifyContent: "space-between",
            background: "#043c64",
            borderTopRightRadius: 3,
            borderTopLeftRadius: 3,
          }}
        >
          <Text color="#ffffff" size={14} m={5} mt={8} ml={15}>
            Bulk Import
          </Text>
          <ActionIcon
            onClick={() => setVariables({ ...variables, bulkDrawer: false })}
            m={5}
            sx={{
              "&[data-disabled]": { opacity: 1 },
              "&[data-loading]": { backgroundColor: "#ffffff" },
            }}
          >
            <X size={18} />
          </ActionIcon>
        </div>
        {/* Drawer content */}
        <div className="zc-p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>

            <div
              onClick={() => onDownload({ data: "product" })}
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                cursor: "pointer",
                paddingTop: 5,
              }}
            >
              <img
                src={excel}
                alt="excel"
                width="25"
                style={{ margin: "2px" }}
              />
            </div>
          </div>
          <Tabs defaultValue="category" mb={20}>
            <Tabs.List>
              <Tabs.Tab value="category" icon={<Box size={14} />}>
                Category
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="category" pt="xs">
              <ScrollArea style={{ height: 150 }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Value</th>
                      <th>Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variables.groupList.map((row) => (
                      <tr key={row.label}>
                        <td>{row.value}</td>
                        <td>{row.label}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            </Tabs.Panel>
            <Tabs.Panel value="category" pt="xs">
              <ScrollArea style={{ height: 150 }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Value</th>
                      <th>Label</th>
                    </tr>
                  </thead>
                </Table>
              </ScrollArea>
            </Tabs.Panel>
            <Tabs.Panel value="subgroup" pt="xs">
              <ScrollArea style={{ height: 150 }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Value</th>
                      <th>Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variables.subgroupList.map((row) => (
                      <tr key={row.label}>
                        <td>{row.value}</td>
                        <td>{row.label}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            </Tabs.Panel>
          </Tabs>

          {/* Group Bulk add file */}
          <label className="zc-custom-file-upload">
            <input type="file" ref={ref} onChange={bulkFile789} />
            <CloudUpload size={15}></CloudUpload> Upload Bulk Excel File
          </label>
          <Group position="right" mt="md">
            <Button
              color="zevcore"
              fullWidth
              style={{
                position: "relative",
                transition: "background-color 150ms ease",
              }}
              onClick={() => {
                loaded
                  ? setLoaded(false)
                  : !interval.active && interval.start();
                BulkDataUpload();
              }}
            >
              <div
                style={{
                  position: "relative",
                  zIndex: 1,
                }}
              >
                {progress !== 0
                  ? "Uploading Each Data...."
                  : loaded
                  ? "Bulk Upload Completed"
                  : "Upload files"}
              </div>
              {progress !== 0 && (
                <Progress
                  value={progress}
                  className={classes.progress}
                  color="zevcore"
                  radius="sm"
                />
              )}
            </Button>
          </Group>
        </div>
      </Modal>
      {/* Group Bulk drawer end */}
      <GoldProduct
        value={goldValue}
        title={goldTitle}
        list={goldData}
        productModal={productModal}
        setProductModal={setProductModal}
      />
    </div>
  );
}
export default Product;
