import React, { useState } from "react";
import Image from "../assets/images/logo.png"; // Import logo image
import { useNavigate } from "react-router-dom";

function Logo() {
  let navigate = useNavigate();
  const [active, setActive] = useState("");
  const handlePage = (e) => {
    if (typeof e != "undefined") {
      navigate(e);
      setActive(e);
    }
  };
  return (
    <div>
      <img
        onClick={() => handlePage("/")}
        src={Image}
        alt=""
        style={{ height: "30px", marginBottom: 8, marginRight: 20 }}
      />
    </div>
  );
}

export default Logo;
