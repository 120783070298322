/**
 * Application Name: Zevcore-WAS
 * Author: Zevcore Private Limited
 * Starting date: 10.08.2022
 * Developer Name: Suhas S
 * Last modified date: 10.09.2022
 * Last modified by: Suhas S
 */

import { Button, Menu } from "@mantine/core"; //For import mantine required functions and theme
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components
import {
  Archive,
  Box,
  ChartLine,
  Dashboard,
  Report,
  Settings,
  User,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon
import useStyles from "./Style"; // Import custom styles

function Navigation() {
  // For navigation
  let navigate = useNavigate();
  const { classes } = useStyles();
  const [active, setActive] = useState("");

  // Handel click if e empty or undefined it wont do any thing
  const handlePage = (e) => {
    if (typeof e != "undefined") {
      navigate(e);
      setActive(e);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
      }}
    >
      {/* Dashboard */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          color="zevcore"
          variant="subtle"
          className={
            active === "/" ? classes.navButtonActive : classes.navButton
          }
          size="xs"
          onClick={() => handlePage("/")}
          leftIcon={<Dashboard size={14} />}
        >
          Dashboard
        </Button>

        {/* Customer */}
        <Button
          color="zevcore"
          variant="subtle"
          className={
            active === "/customers"
              ? classes.navButtonActive
              : classes.navButton
          }
          size="xs"
          onClick={() => handlePage("/customers")}
          leftIcon={<User size={14} />}
        >
          Customers
        </Button>

        {/* Inventory */}
        <Menu width={200} shadow="md" position="bottom-start" withArrow>
          <Menu.Target>
            <Button
              color="zevcore"
              className={
                active === "/stock_in" ||
                active === "/stock" ||
                active === "/po" ||
                active === "/vendors"
                  ? classes.navButtonActive
                  : classes.navButton
              }
              variant="subtle"
              size="xs"
              leftIcon={<Archive size={14} />}
            >
              Inventory
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item color="zevcore" onClick={() => handlePage("/stock_in")}>
              Stock In
            </Menu.Item>
            <Menu.Item color="zevcore" onClick={() => handlePage("/stock")}>
              Stocks
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/product_stock")}
            >
              Product Stocks
            </Menu.Item>
            <Menu.Item color="zevcore" onClick={() => handlePage("/po")}>
              Purchase Order
            </Menu.Item>
            <Menu.Item color="zevcore" onClick={() => handlePage("/vendors")}>
              Vendors
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        {/* Customer */}
        <Button
          color="zevcore"
          variant="subtle"
          className={
            active === "/add_sale" ? classes.navButtonActive : classes.navButton
          }
          size="xs"
          onClick={() => handlePage("/add_sale")}
          leftIcon={<ChartLine size={14} />}
        >
          Sales
        </Button>

        {/* Sales */}
        {/* <Menu width={200} shadow="md" position="bottom-start" withArrow>
          <Menu.Target>
            <Button
              color="zevcore"
              className={
                active === "/add_sale" || active === "/kpi"
                  ? classes.navButtonActive
                  : classes.navButton
              }
              variant="subtle"
              size="xs"
              leftIcon={<ChartLine size={14} />}
            >
              Sales
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item color="zevcore" onClick={() => handlePage("/add_sale")}>
              Add Sale
            </Menu.Item>
            {/* <Menu.Item color="zevcore" onClick={() => handlePage("/sale")}>
              View Sales
            </Menu.Item> */}

        {/* <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/add_estimate")}
            >
              Add Estimate
            </Menu.Item>
            <Menu.Item color="zevcore" onClick={() => handlePage("/estimate")}>
              Estimate
            </Menu.Item> */}
        {/* <Menu.Item color="zevcore" onClick={() => handlePage("/passbook")}>
              Comprehensive Report
            </Menu.Item> 
            <Menu.Item color="zevcore" onClick={() => handlePage("/kpi")}>
              KPI
            </Menu.Item>
          </Menu.Dropdown>
        </Menu> */}

        {/* Ledger */}
        {/* <Menu width={200} shadow="md" position="bottom-start" withArrow>
          <Menu.Target>
            <Button
              color="zevcore"
              className={
                active === "/cash" || active === "/search_cash"
                  ? classes.navButtonActive
                  : classes.navButton
              }
              variant="subtle"
              size="xs"
              leftIcon={<Cash size={14} />}
            >
              Ledger
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item color="zevcore" onClick={() => handlePage("/cash")}>
              Ledger
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/search_cash")}
            >
              Search Cash Ledger
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/search_bank")}
            >
              Search Bank Ledger
            </Menu.Item>
          </Menu.Dropdown>
        </Menu> */}
        {/* Product */}
        <Menu width={200} shadow="md" position="bottom-start" withArrow>
          <Menu.Target>
            <Button
              color="zevcore"
              className={
                active === "/category" || active === "/products"
                  ? classes.navButtonActive
                  : classes.navButton
              }
              variant="subtle"
              size="xs"
              leftIcon={<Box size={14} />}
            >
              Products
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item color="zevcore" onClick={() => handlePage("/category")}>
              View Categories
            </Menu.Item>
            <Menu.Item color="zevcore" onClick={() => handlePage("/products")}>
              View Products
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        {/* Report */}
        <Menu width={200} shadow="md" position="bottom-start" withArrow>
          <Menu.Target>
            <Button
              color="zevcore"
              className={
                active === "/sale" || active === "/sale"
                  ? classes.navButtonActive
                  : classes.navButton
              }
              variant="subtle"
              size="xs"
              leftIcon={<Report size={14} />}
            >
              Reports
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item color="zevcore" onClick={() => handlePage("/sale")}>
              View Sales
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/weekly_sales")}
            >
              Weekly Sales
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/monthly_sales")}
            >
              Monthly Sales
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/gst_report")}
            >
              GST Report
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        {/* Settings */}
        <Button
          color="zevcore"
          variant="subtle"
          className={
            active === "/manages" ? classes.navButtonActive : classes.navButton
          }
          size="xs"
          onClick={() => handlePage("/manages")}
          leftIcon={<Settings size={14} />}
        >
          Settings
        </Button>
      </div>
    </div>
  );
}

export default Navigation;
