import React, { useState, useEffect } from "react"; // Main react importer
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components
import BG from "../../assets/images/bg.png";
import {
  Button,
  Paper,
  PasswordInput,
  TextInput,
  Group,
  createStyles,
  Text,
  ActionIcon,
  HoverCard,
  Container,
  Title,
} from "@mantine/core"; //For import mantine required functions and theme
import Logo from "../../assets/images/logo.png"; // Import logo image
import { useForm } from "@mantine/form"; // Mantine form import
import { handleLogin } from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Helper for import the axios request in open page
import { notificationHelper } from "../../helpers/notification";
import { InfoCircle, QuestionMark } from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon

const useStyles = createStyles((theme) => ({
  // For login page wallpaer style
  wrapper: {
    minHeight: "100%",
    backgroundSize: "cover",
    minWidth: "100%",
    position: "fixed",
    backgroundImage: `url(${BG})`,
  },
}));

// Main login page start here
function Login() {
  let navigate = useNavigate();
  // Mantine custome style use
  const { classes } = useStyles();

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    submitLoading: false,
    userCount: 0,
  });

  // For form validation
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (value.length < 1 ? "Email is required" : null),
      password: (value) => (value.length < 1 ? "Password is required" : null),
    },
  });

  // Submit the login information
  const handleSubmit = async (e) => {
    console.log(e);
    setVariables({ ...variables, submitLoading: true });
    const response = await handleLogin(e);
    console.log(response);
    if (response.status === 200) {
      console.log(response.data.roles);
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("role", response.data.roles);
      localStorage.setItem("isAuthenticated", "true");
      setTimeout(() => {
        navigate("/");
      }, 300);
      notificationHelper({
        color: "green",
        title: "Success",
        message: "You have logged in successfully",
      });
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed!",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <div>
      <Container
        style={{ fontFamily: "Verdana, sans-serif" }}
        size={480}
        my={100}
      >
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: "Verdana, sans-serif",
            fontWeight: 200,
          })}
        >
          {/* Login */}
        </Title>
        {/* For adding left side form style */}
        <Paper withBorder shadow="md" p={20} mt={30} radius="md">
          {/* Main logo in login form */}
          <div justify="center" align="center" style={{ marginBottom: 25 }}>
            <img src={Logo} alt="" style={{ height: "80px" }} />
          </div>

          {/* Form for login or signup user by phone no or email */}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              variant="filled"
              value={form.values.email}
              label="Email address or Phone No"
              placeholder="Enter Email or Phone No"
              size="md"
              {...form.getInputProps("email")}
            />
            <PasswordInput
              variant="filled"
              label="Password"
              placeholder="Your password"
              mt="md"
              size="md"
              value={form.values.password}
              {...form.getInputProps("password")}
            />
            <Button
              mt="xl"
              type="submit"
              fullWidth
              color="zevcore"
              loading={variables.submitLoading}
            >
              Login
            </Button>
          </form>
          <Button
            variant="subtle"
            fullWidth
            mt="md"
            onClick={() => navigate("/forgot_password")}
          >
            Forgot Password
            {/* <HoverCard shadow="md" openDelay={1000}>
              <HoverCard.Target>
                <InfoCircle size={18} />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">
                  For reset password we send otp for register email address
                </Text>
              </HoverCard.Dropdown>
            </HoverCard> */}
          </Button>
        </Paper>
      </Container>
    </div>
  );
}

export default Login;
