/**
 * Application Name: Viprabandhana front End
 * Application Version: 1.0
 * Author: Zevcore Private Limited
 * Created Date: 1.12.2022
 * Last Modified Date: 1.12.2022
 * Developer Name: Anand Krishna
 */

const BASE_URL = `https://sjs.zevcore.cc/`;
// const BASE_URL = `http://192.168.1.17:3007/`;
const API_URL = BASE_URL + "api";

export const URL_CONSTANTS = {
  // Auth
  LOGIN_URL: `${API_URL}/auth/signin`,
  REGISTER_URL: `${API_URL}/auth/signup`,
  // User
  USER_URL: `${API_URL}/user`,
  USER_ONE_URL: `${API_URL}/user_one`,
  USER_PASSWORD_URL: `${API_URL}/update_password`,
  // Account
  ACCOUNT_URL: `${API_URL}/account`,
  // Tax
  TAX_URL: `${API_URL}/tax`,
  // HSN Code
  HSN_URL: `${API_URL}/hsn_code`,
  // Stock Type
  STOCKTYPE_URL: `${API_URL}/stock_type`,
  // Sale Type
  SALETYPE_URL: `${API_URL}/sale_type`,
  // Bank
  BANK_URL: `${API_URL}/bank`,
  // Address
  ADDRESS_URL: `${API_URL}/address`,
  // Customer
  CUSTOMER_URL: `${API_URL}/customer`,
  // Category
  CATEGORY_URL: `${API_URL}/category`,
  // Product
  PRODUCT_URL: `${API_URL}/product`,
  PRODUCT_IMAGE: `${BASE_URL}public/product/`,
  // Vendor
  VENDOR_URL: `${API_URL}/vendor`,
  // Purchase Order
  PO_URL: `${API_URL}/po`,
  // Purchase Order list
  PO_LIST_URL: `${API_URL}/po_list`,
  // Stock
  STOCK_URL: `${API_URL}/stock`,
  PACKETSTOCK_URL: `${API_URL}/packet_stock`,
  PACKETSTOCKCREATE_URL: `${API_URL}/packet_stock_create`,
  // Cash Ledger
  CASHLEDGER_URL: `${API_URL}/cash_ledger`,
  // Bank Ledger
  BANKLEDGER_URL: `${API_URL}/bank_ledger`,
  // Reminder
  REMINDER_URL: `${API_URL}/reminder`,
  // Estimate
  ESTIMATE_URL: `${API_URL}/estimate`,
  // Invoice
  INVOICE_URL: `${API_URL}/invoice`,
  INVOICEONE_URL: `${API_URL}/invoice_one`,
  INVOICE2_URL: `${API_URL}/invoice2`,
  // Save
  SAVEINVOICE_URL: `${API_URL}/save`,
  SAVEINVOICEONE_URL: `${API_URL}/save_one`,
  // Journal
  JOURNAL_URL: `${API_URL}/journal`,
  // Payment
  PAYMENT_URL: `${API_URL}/payment`,
  // Passbook
  PASSBOOK_URL: `${API_URL}/passbook`,
  // DATE Report
  REPORT_DATE_URL: `${API_URL}/reportDate`,
  // Reports
  CUSTOMER_REPORT_URL: `${API_URL}/customerReport`,
  HSN_REPORT_URL: `${API_URL}/hsnReport`,
  KPI_REPORT_URL: `${API_URL}/kpiReport`,
  DASHBOARD_REPORT_URL: `${API_URL}/dashboardReport`,
  STOCKDEP_REPORT_URL: `${API_URL}/stockdepReport`,
  PRODUCTHIST_REPORT_URL: `${API_URL}/productHistReport`,
  PRODUCTHISTDATE_REPORT_URL: `${API_URL}/productHistDateReport`,
  CUSTOMERHIST_REPORT_URL: `${API_URL}/customerHistoryReport`,
  CUSTOMERHIST2_REPORT_URL: `${API_URL}/customerHistoryReport2`,
  CUSTOMERHISTDATE_REPORT_URL: `${API_URL}/customerHistoryDateReport`,
  // Reports
};
